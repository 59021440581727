import * as React from "react";

import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { WidgetOptions } from "./";

export interface BillContextProps {
    accoBillLinesCount: number;
    cartReservedResourceId?: number;
    cmsContext: CMSProviderProperties;
    currencySymbol?: string;
    referencePrice?: number;
    resourceId?: number;
    widgetOptions: WidgetOptions;
}

interface Props extends BillContextProps {
    children: React.ReactNode;
}

const BillContext = React.createContext<BillContextProps>({} as BillContextProps);

export const BillProvider: React.FC<Props> = (props: Props) => {
    const { children, accoBillLinesCount, cartReservedResourceId, cmsContext, currencySymbol, referencePrice, resourceId, widgetOptions } = props;
    return <BillContext.Provider value={{ accoBillLinesCount, cartReservedResourceId, cmsContext, currencySymbol, referencePrice, resourceId, widgetOptions }}>{children}</BillContext.Provider>;
};

export const useBillContext = () => React.useContext(BillContext);
