import * as React from "react";

import { BillLineType, ChoiceResourceType } from "@maxxton/cms-mxts-api";
import { NumberUtil, isDecimal } from "../../../../../utils/number.util";

import { DynamicFilter } from "../../../../../redux/reducers/dynamicFilter.types";
import { State } from "../../../../../redux";
import { useBillContext } from "../../BillContext";
import { useBillLineContext } from "../BillLineContext";
import { useSelector } from "react-redux";

export const BillLinePrice: React.FC = () => {
    const { billLine } = useBillLineContext();
    const { accoBillLinesCount, cmsContext, referencePrice, resourceId, widgetOptions } = useBillContext();
    const dynamicFilter: DynamicFilter = useSelector((state: State) => state.dynamicFilter || {});

    const fractionDigitsForTotal = !((billLine.total && isDecimal(billLine.total)) || cmsContext.site.addDecimalToPrices);
    const fractionDigitsForRefPrice = !((referencePrice && isDecimal(referencePrice)) || cmsContext.site.addDecimalToPrices);
    const isShowCurrency = widgetOptions.showCurrency && billLine.total && billLine.total !== 0;
    const currencyCode = dynamicFilter?.reservationCurrencyCode || dynamicFilter?.currency?.code || cmsContext?.currency?.code;
    return (
        <td className="price">
            {billLine.billLineType !== BillLineType.TOTAL && billLine.billLineType !== BillLineType.SUBTOTAL && billLine.billLineType !== BillLineType.WARRANT && (
                <div>
                    {referencePrice &&
                        billLine.resourceId === resourceId &&
                        billLine.resourceType === ChoiceResourceType.ACCOMMODATIONTYPE &&
                        billLine.total < referencePrice &&
                        accoBillLinesCount < 2 && (
                            <span>
                                <del className="reference-price">
                                    {NumberUtil.priceWithCurrency({
                                        price: referencePrice,
                                        isRelativePrice: fractionDigitsForRefPrice,
                                        context: cmsContext,
                                        currencyCode,
                                        showCurrency: !!isShowCurrency,
                                    })}
                                </del>
                            </span>
                        )}
                    <span>
                        {NumberUtil.priceWithCurrency({
                            price: billLine.total,
                            isRelativePrice: fractionDigitsForTotal,
                            context: cmsContext,
                            currencyCode,
                            showCurrency: !!isShowCurrency,
                        })}
                    </span>
                </div>
            )}
        </td>
    );
};
