import * as React from "react";

import { BillLine } from "@maxxton/cms-mxts-api";
import { SelectedAddOn } from "../../../../redux/reducers/add-ons/selectedAddOnsReducer";

export interface BillLineContextProps {
    addOn?: SelectedAddOn;
    billLine: BillLine;
}

interface Props extends BillLineContextProps {
    children: React.ReactNode;
}

const BillLineContext = React.createContext<BillLineContextProps>({} as BillLineContextProps);

export const BillLineProvider: React.FC<Props> = (props: Props) => {
    const { children, addOn, billLine } = props;
    return <BillLineContext.Provider value={{ addOn, billLine }}>{children}</BillLineContext.Provider>;
};

export const useBillLineContext = () => React.useContext(BillLineContext);
