import * as React from "react";

import { BillLine, BillLineType, ChoiceResourceType } from "@maxxton/cms-mxts-api";
import { WidgetOptions, localizedContent } from "../";

import { AddOnRemoveButton } from "./components/AddOnRemoveButton";
import { BillLinePrice } from "./components/BillLinePrice";
import { BillLineProvider } from "./BillLineContext";
import { BillLineResourceDescription } from "./components/BillLineResourceDescription";
import { CMSProvidedProperties } from "../../../../containers/cmsProvider.types";
import { NumberUtil } from "../../../../utils/number.util";
import { ReservationAddOnButtons } from "./components/ReservationAddOnButtons";
import { SelectedAddOn } from "../../../../redux/reducers/add-ons/selectedAddOnsReducer";
import { State } from "../../../../redux";
import { getI18nLocaleString } from "../../../../i18n";
import { getSelectedAddOnsInsideCart } from "../../add-ons/addOns.util";
import namespaceList from "../../../../i18n/namespaceList";
import { useBillContext } from "../BillContext";
import { useSelector } from "react-redux";

export interface AddOnBillLineProps {
    billLines: BillLine[];
    options: WidgetOptions;
    localizedContent?: localizedContent;
    context: CMSProvidedProperties;
    isMyEnvReservationAddOn: boolean;
    removePreferenceCost(): void;
}

interface SingleAddOnBillLineProps {
    billLine: BillLine;
    zeroPriceBillLineText?: string;
    options?: WidgetOptions;
    removePreferenceCost?: () => void;
}

export const AddOnBillLine: React.FC<AddOnBillLineProps> = (props: AddOnBillLineProps): JSX.Element | null => {
    const { billLines, options, localizedContent, context } = props;
    const { cartReservedResourceId, widgetOptions } = useBillContext();
    const dynamicFilter = useSelector((state: State) => state.dynamicFilter);
    const currencyCode = dynamicFilter?.reservationCurrencyCode || dynamicFilter?.currency?.code || props.context?.currency?.code;
    const localizedZeroPriceText = options.localizedContent?.find((content) => content.locale === context.currentLocale.locale)?.zeroBillPriceText;
    const zeroPriceBillLineText =
        localizedZeroPriceText ||
        NumberUtil.priceWithCurrency({
            price: 0,
            context,
            currencyCode,
            showCurrency: options.showCurrency,
        }) ||
        "";

    return (
        <React.Fragment>
            {options.showTitleAddons && <span className="addition-bill__title">{localizedContent?.localizedTitleAddons}</span>}
            <table className="toc-layout table bill-panel addition-bill-line">
                <tbody>
                    {!props.isMyEnvReservationAddOn &&
                        (options.isBillLinesGrouped ? billLines?.sort((firstValue, secondValue) => secondValue.total - firstValue.total) : billLines).map((billLine: BillLine, billIndex: number) => (
                            <SingleAddOnBillLine
                                billLine={billLine}
                                key={billIndex}
                                zeroPriceBillLineText={zeroPriceBillLineText}
                                options={options}
                                removePreferenceCost={props.removePreferenceCost}
                            />
                        ))}
                    {options.typeOfBillLines.some((typeOfBillLine) => typeOfBillLine.value === BillLineType.MY_ENV_RESERVATION_ADDON) &&
                        (options.isBillLinesGrouped ? billLines?.sort((firstValue, secondValue) => secondValue.total - firstValue.total) : billLines).map((billLine: BillLine, billIndex: number) => (
                            <SingleReservationAddOnBillLine billLine={billLine} key={billIndex} />
                        ))}
                    {options.typeOfBillLines.some((type) => type.value === BillLineType.ADDON_TOTAL_PRICE) && options.useAsShoppingCart && options.targetBill === "myEnvAdditionsBill" && (
                        <tr className="add-ons-total">
                            <td className="add-ons-total__label">{getI18nLocaleString(namespaceList.customerBillWidget, "addonsTotal", context.currentLocale, context.site)}</td>
                            <td className="add-ons-total__price">
                                {NumberUtil.priceWithCurrency({
                                    price: billLines.reduce((total, billLine) => total + billLine.total, 0),
                                    isRelativePrice: true,
                                    context,
                                    currencyCode,
                                    showCurrency: options.showCurrency && billLines.reduce((total, billLine) => total + billLine.total, 0) !== 0,
                                })}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </React.Fragment>
    );
};

const SingleAddOnBillLine = (props: SingleAddOnBillLineProps) => {
    const { billLine, zeroPriceBillLineText, options } = props;
    const { cartReservedResourceId, widgetOptions } = useBillContext();

    const selectedAddOnsInCart = useSelector((state: State) => getSelectedAddOnsInsideCart({ selectedAddOnsState: state.selectedAddOnsState, cartReservedResourceId }));
    const addOn: SelectedAddOn | undefined = selectedAddOnsInCart.find((addOn: SelectedAddOn) => addOn.resourceId === billLine.resourceId);
    const isReservedResource = billLine.billLineType === BillLineType.RESERVED_RESOURCE && !addOn;
    const isIncludedAddon = options?.showIncludedAddOnsWithExtras && isReservedResource && billLine.resourceType !== ChoiceResourceType.EXTRA;
    const isUnitPreference = options?.showUnitPreferenceBillLineWithExtras && isReservedResource && billLine.resourceType === ChoiceResourceType.EXTRA;

    if (!addOn && !isIncludedAddon && !isUnitPreference) {
        return null;
    }
    const addOnResourceId = addOn?.resourceId || billLine.resourceId;

    return (
        <BillLineProvider billLine={billLine} addOn={addOn}>
            <tr key={`add-on-${addOnResourceId}`}>
                <BillLineResourceDescription showExtraAddOnInformation={widgetOptions.showDates} />
                {!isIncludedAddon ? (
                    <BillLinePrice />
                ) : (
                    <td>
                        <div className="bill-panel reservation-inclusive-bill-lines">
                            <span>{zeroPriceBillLineText}</span>
                        </div>
                    </td>
                )}
                {!isIncludedAddon && props.removePreferenceCost && <AddOnRemoveButton removePreferenceCost={props.removePreferenceCost} />}
            </tr>
        </BillLineProvider>
    );
};

const SingleReservationAddOnBillLine = (props: SingleAddOnBillLineProps) => {
    const { billLine } = props;
    const { widgetOptions } = useBillContext();

    return (
        <BillLineProvider billLine={billLine}>
            <tr key={`add-on-${billLine.resourceId}`}>
                <BillLineResourceDescription showExtraAddOnInformation={widgetOptions.showDates} />
                <BillLinePrice />
                <ReservationAddOnButtons reservationAddOnButtonType="update" />
                <ReservationAddOnButtons reservationAddOnButtonType="remove" />
            </tr>
        </BillLineProvider>
    );
};
