import * as React from "react";

import { AddOnRemoveButton } from "./components/AddOnRemoveButton";
import { BillLine } from "@maxxton/cms-mxts-api";
import { BillLinePrice } from "./components/BillLinePrice";
import { BillLineProvider } from "./BillLineContext";
import { BillLineResourceDescription } from "./components/BillLineResourceDescription";
import { PriceTypes } from "../../../page/activityPlanner/activityPlanner.enum";
import { State } from "../../../../redux";
import { cloneDeep } from "lodash";
import { getMxtsEnv } from "../../../mxts";
import { setActivityDynamicCodeInDescription } from "../../description/description.utils";
import { useBillContext } from "../BillContext";
import { useSelector } from "react-redux";

export interface ActivityBillLineProps {
    activityBillLines: BillLine[];
}

interface SingleActivityBillLineProps {
    activityBillLine: BillLine;
}

export const ActivityBillLine: React.FC<ActivityBillLineProps> = (props: ActivityBillLineProps): JSX.Element | null => {
    const { activityBillLines } = props;

    return (
        <React.Fragment>
            <table className="toc-layout table bill-panel activity-bill-line">
                <tbody>
                    {activityBillLines.map((billLine: BillLine, billIndex: number) => (
                        <SingleActivityBillLine activityBillLine={billLine} key={billIndex} />
                    ))}
                </tbody>
            </table>
        </React.Fragment>
    );
};

const SingleActivityBillLine = (props: SingleActivityBillLineProps) => {
    const { selectedActivities } = useSelector((state: State) => state.dynamicFilter);
    const { cmsContext, widgetOptions } = useBillContext();
    const currentLocalized = widgetOptions.localizedContent?.find((content) => content.locale === cmsContext.currentLocale.locale);
    const matchingActivity = selectedActivities?.find((selectedActivity) => selectedActivity.resourceActivityDetailsId === props.activityBillLine.reservedResource?.resourceActivityDetailsId);
    const isFreeActivity = matchingActivity?.resourceActivity.resortActivity.priceType === PriceTypes.FREE;
    const [selectedBillLine, setSelectedBillLine] = React.useState<BillLine>(props.activityBillLine);

    React.useEffect(() => {
        const setDynamicFieldForActivityBillLine = async () => {
            if (matchingActivity) {
                const { useDynamicFieldForActivity, activityDynamicFieldCodes, _id: widgetOptionsId } = widgetOptions;
                const billLine = cloneDeep(props.activityBillLine);
                if (useDynamicFieldForActivity && activityDynamicFieldCodes?.length) {
                    const env = await getMxtsEnv(cmsContext);
                    const selectedActivityWithDynamicFields = await setActivityDynamicCodeInDescription({
                        selectedActivity: matchingActivity,
                        env,
                        context: cmsContext,
                        widgetOptionsId,
                    });
                    const dynamicFieldData = selectedActivityWithDynamicFields.dynamicFieldData?.[0];

                    if (dynamicFieldData?.value) {
                        billLine.resourceName = dynamicFieldData.value;
                        billLine.description = dynamicFieldData.value;
                    }
                }
                setSelectedBillLine(billLine);
            }
        };

        setDynamicFieldForActivityBillLine();
    }, [props.activityBillLine]);

    return (
        <BillLineProvider billLine={selectedBillLine}>
            <tr key={`add-on-${selectedBillLine}`}>
                <BillLineResourceDescription showExtraAddOnInformation={widgetOptions.showDates} />
                {isFreeActivity && currentLocalized?.zeroBillPriceText ? (
                    <td className="price">
                        <span>{currentLocalized?.zeroBillPriceText}</span>
                    </td>
                ) : (
                    <BillLinePrice />
                )}
                <AddOnRemoveButton />
            </tr>
        </BillLineProvider>
    );
};
