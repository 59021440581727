import { getDistributionChannelLazyLoadPage, rateTypeOptions } from "../plugins/mxts";

import { LocalizeDcAndRcOptions } from "../plugins/dynamic/owner/book/choiceSelection";
import { MxtsApi } from "@maxxton/cms-mxts-api";
import { SomeInputSpec } from "../form-specs";
import { getI18nLocaleObject } from "../i18n";
import { getReservationCategorySelectOptions } from "../utils/reservationCategories.util";
import namespaceList from "../i18n/namespaceList";

const localizedDcAndRcOptions = (): Array<SomeInputSpec<any, any>> => [
    {
        variable: "distributionChannelId",
        label: getI18nLocaleObject(namespaceList.widgetUnitSearch, "distributionChannel"),
        type: "lazyLoadAutoComplete",
        lazyLoadOptions: (page: number, searchQuery: string, id) => getDistributionChannelLazyLoadPage(page, searchQuery, id ? [id] : undefined, MxtsApi),
        placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "dcPlaceholder"),
    },
    {
        variable: "mobileDCId",
        label: getI18nLocaleObject(namespaceList.widgetUnitSearch, "distributionChannelMobile"),
        type: "lazyLoadAutoComplete",
        lazyLoadOptions: (page: number, searchQuery: string, id) => getDistributionChannelLazyLoadPage(page, searchQuery, id ? [id] : undefined, MxtsApi),
        placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "dcPlaceholder"),
    },
    {
        variable: "reservationCategoryId",
        label: getI18nLocaleObject(namespaceList.admin, "reservationCategoryId"),
        type: "autocomplete" as const,
        isClearable: false,
        options: async (item: any, spec: any, root: any, tabLocale: string) => getReservationCategorySelectOptions(item, tabLocale),
        placeholder: getI18nLocaleObject(namespaceList.admin, "selectReservationCategoryId"),
        visible: (localizedOptions?: LocalizeDcAndRcOptions) => !!localizedOptions?.distributionChannelId,
    },
    {
        variable: "rateTypes",
        label: getI18nLocaleObject(namespaceList.admin, "rateTypes"),
        type: "multiselect",
        optionList: ({ item, locale }: { item?: any; locale?: string }) => (item && locale ? rateTypeOptions(MxtsApi, item, locale) : []),
        visible: (item?: LocalizeDcAndRcOptions) => !!item?.distributionChannelId,
    },
];

export default localizedDcAndRcOptions;
