import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import { UpdateReservationBillAddonModal } from "./UpdateReservationBillAddonModal";
import { useBillContext } from "../../BillContext";

export interface ReservationAddOnButtonsProps {
    reservationAddOnButtonType: "remove" | "update";
}

export const ReservationAddOnButtons: React.FC<ReservationAddOnButtonsProps> = (props: ReservationAddOnButtonsProps) => {
    const { widgetOptions } = useBillContext();
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const { reservationAddOnButtonType } = props;

    const handleReservationAddOn = () => {
        toggleReservationAddOnModal();
    };

    function toggleReservationAddOnModal() {
        setIsModalOpen(!isModalOpen);
    }

    if (!widgetOptions.removeChoice) {
        return null;
    }

    return (
        <React.Fragment>
            <td className="add-on-removable" onClick={handleReservationAddOn}>
                {reservationAddOnButtonType === "remove" ? <FontAwesome className="remove-icon" name="close" /> : <FontAwesome className="edit-icon" name="edit" />}
            </td>
            {isModalOpen && (
                <UpdateReservationBillAddonModal
                    modalType={reservationAddOnButtonType === "remove" ? "removableModal" : "editableModal"}
                    isModalOpen={isModalOpen}
                    toggleModal={toggleReservationAddOnModal}
                />
            )}
        </React.Fragment>
    );
};
