import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { BillLine, BillLineType, ChoiceResourceType, PriceEngineState } from "@maxxton/cms-mxts-api";
import { CustomerBillProps, CustomerBillState } from "./bill.types";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { NumberUtil, isDecimal } from "../../../utils/number.util";
import { ProductDay, ProductSubjectSelection } from "../additions/products/products.types";
import { getInlineFontStyle, getPreconfiguredFontClassnames } from "../../../utils/fontOptions.util";

import { ActivityBillLine } from "./bill-line/ActivityBillLine";
import { AddOnBillLine } from "./bill-line/AddOnBillLine";
import { BillChoice } from "../../../redux/reducers/billReducer";
import { BillProvider } from "./BillContext";
import { ConfirmAddOnsComponent } from "./ConfirmAddOnsComponent";
import { CurrentLocale } from "../../../app.types";
import { DATE_FORMAT } from "../../../utils/constants";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { FrontendBillLineType } from "./";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { PriceTypes } from "../../page/activityPlanner/activityPlanner.enum";
import { SelectedAddition } from "../additions/additions.types";
import { Site } from "@maxxton/cms-api";
import { getI18nLocaleString } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getTotalBillLines } from "../../../utils/bill.util";
import namespaceList from "../../../i18n/namespaceList";

enum BillType {
    ACCOMMODATION_BILL = "accommodationBill",
    INCLUSIVE_BILL = "inclusiveBill",
    RESORT_ARTICLE = "resortArticle",
}

// eslint-disable-next-line max-lines-per-function
export const billBody = (
    props: CustomerBillProps,
    state: CustomerBillState,
    actions: {
        handlePriceModal(): void;
        removeBillLine(resourceId: number, index: number, checkRemovable?: boolean): boolean | undefined;
        removeAddon(billLine: BillLine): void;
        removePreferenceCost(): void;
        handlePriceBreakDownModal(): void;
    },
    billChoice: BillChoice
): JSX.Element | null => {
    const { className, options, dynamicFilter, unit, availabilityState, context, additionState } = props;
    const {
        priceInfoModalOpen,
        transformedBillAccommodationBillLines,
        transformedAdditionBillLines,
        transformedAddOnBillLines,
        transformedActivityBillLines,
        accoTypeObjectPrefId,
        cartReservedResourceId,
        currencySymbol,
        isBillLoading,
        priceBreakDownModalOpen,
        pricesWithDuration,
    } = state;
    const selectedAdditions = additionState.selectedAdditions;
    const totalBillLines = options.useAsAgentBill ? getTotalBillLines(billChoice.agentBill) : getTotalBillLines(billChoice.customerBill.filter((item) => item.payerType !== "AGENT"));
    const currencyCode = dynamicFilter?.reservationCurrencyCode || dynamicFilter?.currency?.code || context?.currency?.code;
    const { currentLocale, site } = context;
    const { handlePriceModal, removeAddon, removePreferenceCost, handlePriceBreakDownModal } = actions;
    const billLineTypeLabel: string[] = options.typeOfBillLines.map((bl) => bl.value);
    let resourceId: number | undefined = dynamicFilter.resourceid;
    const accommodationType = availabilityState?.availabilityResult?.response?.resources?.find((res: any) => res.resourceId === resourceId);

    const referencePrice = options.showReferencePrice ? accommodationType?.basePriceInclusive : undefined;
    const isRefPriceRelative = !((referencePrice && isDecimal(referencePrice)) || options.addDecimalToBillPrices || context.site.addDecimalToPrices);
    const formattedRefPrice = NumberUtil.priceWithCurrency({
        price: referencePrice,
        isRelativePrice: isRefPriceRelative,
        context,
        currencyCode,
    });
    const zeroPrice = NumberUtil.priceWithCurrency({
        price: 0,
        context,
        currencyCode,
        showCurrency: options.showCurrency,
    });
    const { enableWidgetTitle, useTitleHeadings, styleWidgetTitle, fontColor, useForWorkOrderReservation, workOrderLocalizedOptions } = options;
    const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
    const accoBillLinesCount: number =
        transformedBillAccommodationBillLines?.filter(
            (accoBillLine: BillLine) => accoBillLine.billLineType === BillLineType.RESERVED_RESOURCE && accoBillLine.resourceType === ChoiceResourceType.ACCOMMODATIONTYPE
        ).length || 0;

    const currentLocalized = options.localizedContent?.find((content) => content.locale === context.currentLocale.locale);
    if (!dynamicFilter.resourceid) {
        // If dynamic filter doesn't have resource id (or not present in url)
        resourceId = accommodationType?.resourceId || unit?.resourceId;
    }
    const accommodationBillLineResourceName = (billLine: BillLine) => {
        let resourceName: string | undefined;
        if (
            (options.useConfiguredCashflowRuleName && options.useCashflowruleNameForSpecial && billLine.resourceType === ChoiceResourceType.SPECIAL) ||
            (options.useConfiguredCashflowRuleName && options.useCashflowruleNameForAccoType && billLine.resourceType === ChoiceResourceType.ACCOMMODATIONTYPE)
        ) {
            resourceName = billLine.cashflowruleName;
        } else {
            resourceName = billLine.resourceName;
        }
        if (options.useAccommodationCodeInBillLine) {
            resourceName = `${resourceName} (${billLine.reservedResource?.code}) `;
        }

        /* jscpd:ignore-start */
        return (
            <React.Fragment>
                {options.quantity && <td className="counts">{billLine.multiplier ? `${billLine.multiplier}x ` : ""}</td>}

                <td
                    className={classNames(
                        `${options?.enablePriceBreakDown ? "desc breakdown-resource-description" : "desc"}`,
                        billLine.billLineType,
                        { "no-border": !!options.removeBorder },
                        getPreconfiguredFontClassnames(options.fontConfiguration)
                    )}
                    style={getInlineFontStyle(options.fontConfiguration)}
                >
                    {resourceName || billLine.description}
                    {options.showDescriptionForChoice &&
                        (billLine.resourceName || billLine.resourceDescription) &&
                        billLine.resourceType !== ChoiceResourceType.ACCOMMODATIONTYPE &&
                        billLine.billLineType !== BillLineType.TOTAL &&
                        billLine.billLineType !== BillLineType.SUBTOTAL && (
                            <span onClick={handlePriceModal} className={`ml-2 addition__product ${options.priceInfoText === "modal" ? "info-modal" : "pop-over-link info-hover"}`}>
                                <span className={options.priceInfoText === "modal" ? "info-modal" : "info-hover"}>
                                    <FontAwesome name="info-circle" />
                                </span>
                                {options.priceInfoText === "modal" ? (
                                    <Modal isOpen={priceInfoModalOpen} toggle={handlePriceModal} size="s" className="bill-info-modal">
                                        <ModalHeader tag="h4" toggle={handlePriceModal} className="no-background" />
                                        <ModalBody>
                                            <div>{billLine.resourceDescription || resourceName}</div>
                                        </ModalBody>
                                    </Modal>
                                ) : (
                                    resourceName && <div className="pop-over-content bill-info-hover">{billLine.resourceDescription || resourceName}</div>
                                )}
                            </span>
                        )}
                    {options.showDates ? (
                        <div className="bill-dates">
                            {selectedAdditions && renderExtraInformationForAddition({ resourceId: billLine.resourceId, selectedAdditions, dynamicFilter, currentLocale, site })}
                        </div>
                    ) : null}
                    {billLine?.reservedResource?.type === ChoiceResourceType.ACCOMMODATIONTYPE &&
                        dynamicFilter?.resourceid &&
                        billLine.billLineType === BillLineType.RESERVED_RESOURCE &&
                        options?.enablePriceBreakDown && (
                            <div className="info-icon-container">
                                <FontAwesome name="info-circle" onClick={handlePriceBreakDownModal} />
                                {priceBreakDownModalOpen && (
                                    <Modal isOpen={priceBreakDownModalOpen} toggle={handlePriceBreakDownModal} size="s" className="price-breakdown-modal">
                                        <ModalHeader tag="h4" toggle={handlePriceBreakDownModal} />
                                        <ModalBody>
                                            <Table className="price-breakdown-table">
                                                <thead>
                                                    <tr>
                                                        <th>{getI18nLocaleString(namespaceList.customerBillWidget, "priceBreakdownTitle")}</th>
                                                        <th>{getI18nLocaleString(namespaceList.customerBillWidget, "priceBreakdownUnitLabel")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pricesWithDuration?.map((price, index) => (
                                                        <tr key={index}>
                                                            <td>{price.arrivalDate}</td>
                                                            <td>
                                                                {NumberUtil.priceWithCurrency({
                                                                    price: price?.prices?.baseNightPrice,
                                                                    context,
                                                                    currencyCode,
                                                                })}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </ModalBody>
                                    </Modal>
                                )}
                            </div>
                        )}
                </td>
            </React.Fragment>
        );
        /* jscpd:ignore-end */
    };

    /* jscpd:ignore-start */
    const accommodationBillLineWithPrice = (billLine: BillLine) => {
        const isTotalPriceRelative = !(billLine.total && isDecimal(billLine.total)) && !(options.addDecimalToBillPrices || context.site.addDecimalToPrices);
        const isBillDueOverridden = (props.isMyEnvWidget || dynamicFilter.reservationId) && billLine.virtualOverrideValue !== null;
        const billValue = isBillDueOverridden && billLine.virtualOverrideValue !== undefined ? billLine.virtualOverrideValue : billLine.total;
        return (
            <td
                className={classNames("price", { "no-border": !!options.removeBorder }, getPreconfiguredFontClassnames(options.fontConfiguration))}
                style={getInlineFontStyle(options.fontConfiguration)}
            >
                {billLine.billLineType !== BillLineType.TOTAL && billLine.billLineType !== BillLineType.SUBTOTAL && billLine.billLineType !== BillLineType.WARRANT && (
                    <div>
                        {referencePrice &&
                            billLine.resourceId === resourceId &&
                            billLine.resourceType === ChoiceResourceType.ACCOMMODATIONTYPE &&
                            billLine.total < referencePrice &&
                            formattedRefPrice &&
                            accoBillLinesCount < 2 && (
                                <span>
                                    <del className="reference-price">{formattedRefPrice}</del>
                                </span>
                            )}
                        <span>
                            {NumberUtil.priceWithCurrency({
                                price: billValue,
                                isRelativePrice: isTotalPriceRelative,
                                context,
                                currencyCode,
                                showCurrency: options.showCurrency && billLine?.total !== 0,
                            })}
                        </span>
                    </div>
                )}
            </td>
        );
    };
    /* jscpd:ignore-end */

    const accommodationBillLineWithInclusive = (billLine: BillLine) => (
        <td
            className={classNames("price customize-bill-price", { "no-border": !!options.removeBorder }, getPreconfiguredFontClassnames(options.fontConfiguration))}
            style={getInlineFontStyle(options.fontConfiguration)}
            colSpan={currentLocalized && currentLocalized.zeroBillPriceText ? 2 : undefined}
        >
            {billLine.billLineType !== BillLineType.TOTAL && billLine.billLineType !== BillLineType.SUBTOTAL && billLine.billLineType !== BillLineType.WARRANT && (
                <div className="bill-panel reservation-inclusive-bill-lines">
                    <span>
                        {currentLocalized && currentLocalized.zeroBillPriceText && shouldApplyCustomZeroBillPriceText(billLine) ? currentLocalized.zeroBillPriceText : zeroPrice}
                        {currentLocalized &&
                            !currentLocalized.zeroBillPriceText &&
                            referencePrice &&
                            !billLine.total &&
                            billLine.resourceId === resourceId &&
                            billLine.resourceType === ChoiceResourceType.ACCOMMODATIONTYPE &&
                            billLine.total < referencePrice &&
                            accoBillLinesCount < 2 &&
                            formattedRefPrice && (
                                <div>
                                    <del>{formattedRefPrice}</del>
                                </div>
                            )}
                    </span>
                </div>
            )}
        </td>
    );

    const showExcludingVatPrice = (billLine: BillLine) => {
        if (options.showExcludingVatPrice && billLine.total && billLine.total !== 0) {
            const isRelativePrice = !((billLine.totalExclusiveVat && isDecimal(billLine.totalExclusiveVat)) || options.addDecimalToBillPrices || context.site.addDecimalToPrices);
            return (
                <td className="vat">
                    <span>
                        {NumberUtil.priceWithCurrency({
                            price: billLine.totalExclusiveVat,
                            isRelativePrice,
                            context,
                            currencyCode,
                            showCurrency: options.showCurrency && billLine?.total !== 0,
                        })}
                    </span>
                </td>
            );
        }
    };

    const showRemovalPreferenceIcon = (billLine: BillLine) => {
        // show cross icon for unit preference cost
        if (billLine.resourceId && accoTypeObjectPrefId && billLine.resourceId === accoTypeObjectPrefId) {
            return (
                <td className="add-on-removable" onClick={() => removePreferenceCost()}>
                    <FontAwesome className="remove-icon" name="close" />
                </td>
            );
        }
    };

    const tableBody = (billLineType: string, billType?: BillType) => (
        <tbody>
            {transformedBillAccommodationBillLines
                ?.filter((billLine: BillLine) => billLineType === billLine.billLineType)
                .map((billLine: BillLine, billIndex: number) => {
                    const isBillLineSeparated = options.enableSeparateBillLine ? billLine.billLineType !== BillLineType.DUE_AMOUNT && billLine.billLineType !== BillLineType.PAID_AMOUNT : true;
                    const shouldShowSpecialTitle = billLine.resourceType === ChoiceResourceType.SPECIAL && options.showTitleForSpecials && currentLocalized?.localizedTitleSpecials;
                    // Only show that bill line which is selected in widget options
                    if (!options.isBillLinesGrouped) {
                        // it will render bills when billines are not grouped
                        return (
                            <tr key={billIndex}>
                                {accommodationBillLineResourceName(billLine)}
                                {billLine.total && billLine.total !== 0 ? accommodationBillLineWithPrice(billLine) : accommodationBillLineWithInclusive(billLine)}
                                {showExcludingVatPrice(billLine)}
                                {showRemovalPreferenceIcon(billLine)}
                            </tr>
                        );
                    } else if (billType === BillType.ACCOMMODATION_BILL) {
                        // it will render accommodation bill lines
                        return (
                            <React.Fragment>
                                {shouldShowSpecialTitle && <span className="special-bill__title">{currentLocalized?.localizedTitleSpecials}</span>}
                                {billLine.total !== 0 && isBillLineSeparated && (
                                    <tr key={billIndex}>
                                        {accommodationBillLineResourceName(billLine)}
                                        {accommodationBillLineWithPrice(billLine)}
                                        {showExcludingVatPrice(billLine)}
                                        {showRemovalPreferenceIcon(billLine)}
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    } else if (billType === BillType.INCLUSIVE_BILL) {
                        // it will render Inclusive bill lines
                        return (
                            billLine.total === 0 && (
                                <tr key={billIndex}>
                                    {accommodationBillLineResourceName(billLine)}
                                    {accommodationBillLineWithInclusive(billLine)}
                                    {showRemovalPreferenceIcon(billLine)}
                                </tr>
                            )
                        );
                    } else if (billLineType === billLine.billLineType && (billLineType === BillLineType.RESORT_ARTICLE || billLineType === BillLineType.RESORT_ARTICLES_TOTAL)) {
                        // it will render resort article lines
                        return (
                            <tr key={billIndex}>
                                {accommodationBillLineResourceName(billLine)}
                                {accommodationBillLineWithPrice(billLine)}
                            </tr>
                        );
                    }
                    return (
                        <tr key={billIndex}>
                            {accommodationBillLineResourceName(billLine)}
                            {billLine.total && billLine.total !== 0 ? accommodationBillLineWithPrice(billLine) : accommodationBillLineWithInclusive(billLine)}
                            {showExcludingVatPrice(billLine)}
                            {showRemovalPreferenceIcon(billLine)}
                        </tr>
                    );
                })}
        </tbody>
    );
    const getSeparateBillLine = (billType?: BillType, paymentBillLineType?: string) => (
        <React.Fragment>
            {billLineTypeLabel?.map((billLineType: BillLineType | FrontendBillLineType) =>
                transformedBillAccommodationBillLines?.map((billLine: BillLine, billIndex: number) => {
                    if (billLineType === billLine.billLineType && billLineType === paymentBillLineType) {
                        if (billLine.total !== 0 && billType === BillType.ACCOMMODATION_BILL) {
                            return (
                                <tr
                                    key={billIndex}
                                    className={
                                        options.enableSeparateBillLine && billLine.billLineType === BillLineType.PAID_AMOUNT
                                            ? "paid-amount"
                                            : billLine.billLineType === BillLineType.DUE_AMOUNT
                                            ? "due-amount"
                                            : ""
                                    }
                                >
                                    {accommodationBillLineResourceName(billLine)}
                                    {accommodationBillLineWithPrice(billLine)}
                                    {showExcludingVatPrice(billLine)}
                                    {showRemovalPreferenceIcon(billLine)}
                                </tr>
                            );
                        }
                    }
                })
            )}
        </React.Fragment>
    );
    // JSX for a Choice, Addition, etc bill
    if (billChoice.customerBill || (options.useAsAgentBill && billChoice.agentBill)) {
        return (
            <BillProvider
                accoBillLinesCount={accoBillLinesCount}
                cartReservedResourceId={cartReservedResourceId}
                cmsContext={props.context}
                currencySymbol={currencySymbol}
                referencePrice={referencePrice}
                resourceId={resourceId}
                widgetOptions={options}
            >
                <div className={classNames("customer-bill", className, (billChoice.fetching || isBillLoading) && "loading")}>
                    <LocalizedTitleAndLabel
                        localizedTitle={localizedWidgetTitle}
                        enableWidgetTitle={enableWidgetTitle}
                        useTitleHeadings={useTitleHeadings}
                        styleWidgetTitle={styleWidgetTitle}
                        className={classNames("widget-heading", `${fontColor?.includes("theme") && `color-${fontColor}`}`)}
                        style={classNames(fontColor?.includes("rgba") && fontColor)}
                    />
                    {billChoice.fetching && <div className="bill-overlay" />}
                    {options.showLabelAboveBill && (
                        <div className="bill-description">
                            <span className={"bill-description__label"}>{getI18nLocaleString(namespaceList.widgetCard, "description")}</span>
                            <span className={"bill-description__label"}>{getI18nLocaleString(namespaceList.widgetSearchfacet, "price")}</span>
                        </div>
                    )}
                    {/* eslint-disable-next-line max-lines-per-function */}
                    {billLineTypeLabel.map((billLineType, billIndex) => {
                        if (
                            !(
                                billLineType === BillLineType.ADDON ||
                                billLineType === BillLineType.MY_ENV_RESERVATION_ADDON ||
                                billLineType === BillLineType.ACTIVITY ||
                                billLineType === BillLineType.TOTAL ||
                                billLineType === BillLineType.SUBTOTAL ||
                                billLineType === BillLineType.TOTAL_EXCL_TAX ||
                                billLineType === BillLineType.TOTAL_EXCL_VAT ||
                                billLineType === BillLineType.WARRANT ||
                                billLineType === BillLineType.RESORT_ARTICLES_TOTAL
                            )
                        ) {
                            if (options?.enableSeparateBillLine && (billLineType === BillLineType.DUE_AMOUNT || billLineType === BillLineType.PAID_AMOUNT)) {
                                return <table className="toc-layout table payment-bill-lines">{getSeparateBillLine(BillType.ACCOMMODATION_BILL, billLineType)}</table>;
                            }
                            if (billLineType === "RESORT_ARTICLE_LABEL") {
                                return <span className="resort-article-label">{currentLocalized?.resortArticleLabel || ""}</span>;
                            }
                            return (
                                !options.useAsShoppingCart &&
                                (!options.isBillLinesGrouped ? (
                                    <table key={billIndex} className="toc-layout table bill-panel accommodation-bill-line">
                                        {tableBody(billLineType)}
                                    </table>
                                ) : (
                                    <React.Fragment key={billIndex}>
                                        <table className="toc-layout table bill-panel accommodation-bill-line">{tableBody(billLineType, BillType.ACCOMMODATION_BILL)}</table>
                                        <table className="toc-layout table bill-panel reservation-inclusive-bill-lines">{tableBody(billLineType, BillType.INCLUSIVE_BILL)}</table>
                                    </React.Fragment>
                                ))
                            );
                        } else if (billLineType === BillLineType.ADDON || billLineType === BillLineType.MY_ENV_RESERVATION_ADDON) {
                            return (
                                <React.Fragment key={billIndex}>
                                    {!!transformedAdditionBillLines?.length && (
                                        <React.Fragment>
                                            {options.showTitleAddons && <span className="addition-bill__title">{currentLocalized?.localizedTitleAddons}</span>}
                                            <table className="toc-layout table bill-panel addition-bill-line">
                                                <tbody>
                                                    {(options.isBillLinesGrouped
                                                        ? transformedAdditionBillLines?.sort((firstValue, secondValue) => secondValue.total - firstValue.total)
                                                        : transformedAdditionBillLines
                                                    ).map((billLine: BillLine, billIndex: number) => {
                                                        if (!billLine.state || billLine.state !== PriceEngineState.DELETED) {
                                                            const isTotalPriceRelative = !(
                                                                (billLine.total && isDecimal(billLine.total)) ||
                                                                options.addDecimalToBillPrices ||
                                                                context.site.addDecimalToPrices
                                                            );
                                                            return (
                                                                // Only show that bill line which is selected in widget options
                                                                <tr key={billIndex}>
                                                                    {accommodationBillLineResourceName(billLine)}
                                                                    {billLine.total !== 0 ? (
                                                                        <React.Fragment>
                                                                            {/* jscpd:ignore-start */}
                                                                            <td className="price">
                                                                                {billLine.billLineType !== BillLineType.TOTAL &&
                                                                                    billLine.billLineType !== BillLineType.SUBTOTAL &&
                                                                                    billLine.billLineType !== BillLineType.WARRANT && (
                                                                                        <div>
                                                                                            {referencePrice &&
                                                                                                billLine.resourceId === resourceId &&
                                                                                                billLine.resourceType === ChoiceResourceType.ACCOMMODATIONTYPE &&
                                                                                                billLine.total < referencePrice &&
                                                                                                accoBillLinesCount < 2 &&
                                                                                                formattedRefPrice && (
                                                                                                    <span>
                                                                                                        <del>{formattedRefPrice}</del>
                                                                                                    </span>
                                                                                                )}
                                                                                            {billLine?.total !== 0 && (
                                                                                                <span>
                                                                                                    {NumberUtil.priceWithCurrency({
                                                                                                        price: billLine.total,
                                                                                                        isRelativePrice: isTotalPriceRelative,
                                                                                                        context,
                                                                                                        currencyCode,
                                                                                                        showCurrency: options.showCurrency && billLine?.total !== 0,
                                                                                                    })}
                                                                                                </span>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                            </td>
                                                                            {options.removeChoice && (
                                                                                <td className="add-on-removable" onClick={() => removeAddon(billLine)}>
                                                                                    <FontAwesome className="remove-icon" name="close" />
                                                                                </td>
                                                                            )}
                                                                            {/* jscpd:ignore-end */}
                                                                        </React.Fragment>
                                                                    ) : null}
                                                                </tr>
                                                            );
                                                        }
                                                    })}
                                                </tbody>
                                            </table>
                                        </React.Fragment>
                                    )}
                                    {!!transformedAddOnBillLines?.length && (
                                        <AddOnBillLine
                                            options={options}
                                            localizedContent={currentLocalized}
                                            billLines={transformedAddOnBillLines}
                                            context={context}
                                            isMyEnvReservationAddOn={billLineType === BillLineType.MY_ENV_RESERVATION_ADDON}
                                            removePreferenceCost={removePreferenceCost}
                                        />
                                    )}
                                    {options.useAsShoppingCart && !transformedAddOnBillLines?.length && (
                                        <table className="toc-layout table bill-panel addition-bill-line">
                                            <tbody>
                                                <td>{getI18nLocaleString(namespaceList.customerBillWidget, "noAddonsSelectedMessage", currentLocale, site)}</td>
                                            </tbody>
                                        </table>
                                    )}
                                </React.Fragment>
                            );
                        } else if (billLineType === BillLineType.ACTIVITY) {
                            return <React.Fragment key={billIndex}>{!!transformedActivityBillLines?.length && <ActivityBillLine activityBillLines={transformedActivityBillLines} />}</React.Fragment>;
                        } else if (
                            billLineType === BillLineType.TOTAL ||
                            billLineType === BillLineType.SUBTOTAL ||
                            billLineType === BillLineType.TOTAL_EXCL_TAX ||
                            billLineType === BillLineType.TOTAL_EXCL_VAT ||
                            billLineType === BillLineType.WARRANT ||
                            billLineType === BillLineType.RESORT_ARTICLES_TOTAL
                        ) {
                            return (
                                <React.Fragment key={billIndex}>
                                    <table className={`toc-layout table bill-panel ${options.enableSeparateBillLine ? "payment-bill-line" : "total-bill-line"}`}>
                                        <tbody>
                                            {totalBillLines
                                                ?.filter((billLine: BillLine) => billLine.state !== "DELETED")
                                                .map((billLine: BillLine, billIndex: number) => {
                                                    if (billLineType === billLine.billLineType) {
                                                        const useTotalExclusiveVat = billLine.billLineType === BillLineType.TOTAL_EXCL_VAT;
                                                        const isBillDueOverridden = (props.isMyEnvWidget || dynamicFilter.reservationId) && billLine.virtualOverrideValue !== null;
                                                        const billTotal = isBillDueOverridden && billLine.virtualOverrideValue !== undefined ? billLine.virtualOverrideValue : billLine.total;
                                                        let totalPrice: number | undefined;

                                                        if (options.useAsShoppingCart && transformedAddOnBillLines?.length) {
                                                            totalPrice = transformedAddOnBillLines.reduce((total: number, billLine: BillLine): number => {
                                                                if (useTotalExclusiveVat) {
                                                                    return total + Number(billLine.totalExclusiveVat);
                                                                }
                                                                return total + billTotal;
                                                            }, 0);
                                                        } else {
                                                            totalPrice = useTotalExclusiveVat ? billLine.totalExclusiveVat : billTotal;
                                                        }

                                                        const isPaidActivity = dynamicFilter.selectedActivities?.[0]?.resourceActivity?.resortActivity?.priceType === PriceTypes.PAID;
                                                        const isActivityPaidOnArrival = !!dynamicFilter.selectedActivities?.[0].resourceActivity.productTypeResortArticle;
                                                        const isTotalPriceRelative = !((totalPrice && isDecimal(totalPrice)) || options.addDecimalToBillPrices || context.site.addDecimalToPrices);

                                                        // Only show the relevant total bill line for Activities based on the pay-on-arrival status
                                                        if (
                                                            (isActivityPaidOnArrival && billLine.billLineType === BillLineType.TOTAL) ||
                                                            (!isActivityPaidOnArrival && billLine.billLineType === BillLineType.RESORT_ARTICLES_TOTAL)
                                                        ) {
                                                            return null;
                                                        }

                                                        // Only show that bill line which is selected in widget options
                                                        return (
                                                            <tr key={billIndex}>
                                                                <td className={`desc ${billLine.billLineType}`}>
                                                                    <span>{billLine.resourceName || billLine.description}</span>
                                                                </td>
                                                                {
                                                                    // For not showing 0 value amounts, or total excl vat
                                                                    billLine.total === 0 && !isPaidActivity && !isActivityPaidOnArrival ? (
                                                                        <td className="price ">
                                                                            <span>{currentLocalized?.zeroBillPriceText}</span>
                                                                        </td>
                                                                    ) : billLine.total != null ? (
                                                                        <td className="price">
                                                                            <span>
                                                                                {NumberUtil.priceWithCurrency({
                                                                                    price: totalPrice,
                                                                                    isRelativePrice: isTotalPriceRelative,
                                                                                    context,
                                                                                    currencyCode,
                                                                                    showCurrency: options.showCurrency && billLine?.total !== 0,
                                                                                })}
                                                                            </span>
                                                                        </td>
                                                                    ) : null
                                                                }
                                                            </tr>
                                                        );
                                                    }
                                                })}
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            );
                        }
                    })}

                    {options.targetBill === "myEnvAdditionsBill" && !options.useDynamicSearchButton && (
                        <ConfirmAddOnsComponent
                            isMyEnvWidget={props.isMyEnvWidget}
                            context={props.context}
                            linking={options.linking}
                            useForWorkOrderReservation={useForWorkOrderReservation}
                            workOrderLocalizedOptions={workOrderLocalizedOptions}
                        />
                    )}
                </div>
            </BillProvider>
        );
    }
    return <div />;
};

function renderExtraInformationForAddition(params: {
    resourceId: number;
    selectedAdditions: { [key: string]: SelectedAddition };
    dynamicFilter: DynamicFilter;
    currentLocale: CurrentLocale;
    site: Site;
}): JSX.Element[] {
    const { resourceId, selectedAdditions, dynamicFilter, currentLocale, site } = params;
    const addonsList: JSX.Element[] = [];
    let showDateRange = false;
    Object.keys(selectedAdditions).map((additionId) => {
        if (resourceId?.toString() === additionId) {
            selectedAdditions[additionId].daysAndSubjectsConfig?.map((daysAndSubjects: { totalPrice?: number; days?: ProductDay[]; subjects?: ProductSubjectSelection[] }) => {
                if (daysAndSubjects.subjects?.length) {
                    // Show subject based addons
                    daysAndSubjects.subjects.map((subject: ProductSubjectSelection, index: number) => {
                        if (subject.quantity) {
                            addonsList.push(
                                <React.Fragment key={index}>
                                    <span className="subject-per-addon">
                                        {subject.quantity} {subject.name}
                                    </span>
                                    <br />
                                </React.Fragment>
                            );
                        }
                    });
                }
                if (daysAndSubjects.days?.length && daysAndSubjects.days.some((daysAndSubject) => daysAndSubject.checked)) {
                    // Show day date (checkbox dates) based addons
                    daysAndSubjects.days.map((day: ProductDay, index: number) => {
                        if (day.checked) {
                            addonsList.push(
                                <React.Fragment key={index}>
                                    <span className="day-per-addon">{moment(day.date).format(DATE_FORMAT.DAY_WITH_DATE)}</span>
                                    <br />
                                </React.Fragment>
                            );
                        }
                    });
                } else {
                    showDateRange = true;
                }
            });
            if ((showDateRange || !selectedAdditions[additionId].daysAndSubjectsConfig) && selectedAdditions[additionId].startDate && selectedAdditions[additionId].endDate) {
                // Show date range based addons
                if (
                    dynamicFilter.startdate === moment(selectedAdditions[additionId].startDate).format(DATE_FORMAT.DEFAULT) &&
                    dynamicFilter.enddate === moment(selectedAdditions[additionId].endDate).format(DATE_FORMAT.DEFAULT)
                ) {
                    // Shouldn't show dates if arrival and departure date from reservation/accommodation are same as addons dates.
                    return;
                }
                if (selectedAdditions[additionId].startDate === selectedAdditions[additionId].endDate) {
                    // when start and end dates are same then, only start date will be visible.
                    selectedAdditions[additionId].endDate = undefined;
                }
                addonsList.push(
                    <React.Fragment key={additionId}>
                        <span className="date-per-addon">
                            {`${moment(selectedAdditions[additionId].startDate).format(DATE_FORMAT.DAY_WITH_DATE)} ${getI18nLocaleString(
                                namespaceList.customerBillWidget,
                                "till",
                                currentLocale,
                                site
                            )} ${moment(selectedAdditions[additionId].endDate).format(DATE_FORMAT.DAY_WITH_DATE)}` || ""}
                        </span>
                        <br />
                    </React.Fragment>
                );
            }
        }
    });
    return addonsList;
}
function shouldApplyCustomZeroBillPriceText(billLine: BillLine) {
    return billLine.billLineType !== BillLineType.TO_PAY && billLine.billLineType !== BillLineType.PAID_AMOUNT && billLine.billLineType !== BillLineType.DUE_AMOUNT;
}
