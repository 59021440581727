/* eslint-disable max-lines-per-function */
import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { AddOn, BillLine, MxtsApi, ReservedResource } from "@maxxton/cms-mxts-api";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledAlert } from "reactstrap";
import { CMSProvidedProperties, CMSProviderProperties } from "../../../../../containers/cmsProvider.types";
import { useDispatch, useSelector } from "react-redux";

import { ActionType } from "../../../../../redux/actions";
import { AnyAction } from "redux";
import { Close } from "@mui/icons-material";
import { DynamicFilter } from "../../../../../redux/reducers/dynamicFilter.types";
import { NumberUtil } from "../../../../../utils/number.util";
import { SendingStatus } from "../../../description/description.utils";
import { SetReservationAction } from "../../../../../redux/actions/reservationAction";
import { SpinnerInput } from "../../../../../components/generic-form/spinnerInput";
import { State } from "../../../../../redux";
import { getI18nLocaleString } from "../../../../../i18n";
import { getMxtsEnv } from "../../../../mxts";
import namespaceList from "../../../../../i18n/namespaceList";
import { useBillContext } from "../../BillContext";
import { useBillLineContext } from "../BillLineContext";

export interface updateReservationBillAddOnModalProps {
    modalType: "editableModal" | "removableModal";
    isModalOpen: boolean;
    toggleModal: () => void;
}

export const UpdateReservationBillAddonModal: React.FC<updateReservationBillAddOnModalProps> = (props: updateReservationBillAddOnModalProps) => {
    const { billLine } = useBillLineContext();
    const { widgetOptions, cmsContext } = useBillContext();
    const [addOnQuantity, setAddOnQuantity] = React.useState<number>(billLine.quantity);
    const [addOnUpdating, setAddOnUpdating] = React.useState<boolean>(false);
    const [addOnRemoving, setAddOnRemoving] = React.useState<boolean>(false);
    const [addOnUpdated, setAddOnUpdated] = React.useState<SendingStatus | undefined>();
    const [addOnRemoved, setAddOnRemoved] = React.useState<SendingStatus | undefined>();
    const [billLineReservedResource, setBillLineReservedResource] = React.useState<ReservedResource>();
    const [updationAllowed, setUpdationAllowed] = React.useState<boolean>(true);
    const [removeAllowed, setRemoveAllowed] = React.useState<boolean>(true);
    const [oneDayOrLessRemainingBeforeArrival, setOneDayOrLessRemainingBeforeArrival] = React.useState<boolean>(false);
    const { toggleModal, isModalOpen, modalType } = props;
    const myEnvState = useSelector((state: State) => state.myEnvState);
    const addOnsState = useSelector((state: State) => state.addOnsState);
    const dispatchAction = useDispatch();

    React.useEffect(() => {
        (async () => {
            if (billLine.reservationId && billLine.reservedResourceId) {
                const env = await getMxtsEnv(cmsContext);
                const reservedResources: ReservedResource = await MxtsApi.getReservedResourceFromId(env, {}, [
                    { key: "reservationId", value: billLine.reservationId },
                    { key: "reservedResourceId", value: billLine.reservedResourceId },
                ]);
                setBillLineReservedResource(reservedResources);
            }
        })();
    }, [billLine]);

    React.useEffect(() => {
        const currentDate = moment();
        const reservationArrivalDate = moment(myEnvState.selectedReservation?.reservation.arrivalDate);
        const removable = !!(billLineReservedResource?.removable && reservationArrivalDate.diff(currentDate, "days") > 1);
        setRemoveAllowed(removable);
        const updatable = addOnQuantity !== billLine.quantity && reservationArrivalDate.diff(currentDate, "days") > 1;
        setUpdationAllowed(updatable);
        setOneDayOrLessRemainingBeforeArrival(reservationArrivalDate.diff(currentDate, "days") <= 1);
    }, [addOnQuantity, billLine.quantity, billLineReservedResource?.removable, myEnvState.selectedReservation?.reservation.arrivalDate]);

    function updateAddOnInsideBill() {
        setAddOnUpdating(true);
        setAddOnUpdated(undefined);
        const reservationId = billLine.reservationId || billLine.reservedResource?.reservationId;
        const reservedResourceId = billLine.reservedResourceId || billLine.reservedResource?.reservedResourceId;
        if (reservationId && reservedResourceId) {
            updateReservationReservedResource({ addOnQuantity, billLine, cmsContext, reservationId, reservedResourceId, setAddOnUpdated, setAddOnUpdating, dispatchAction });
        }
    }

    function removeAddOnFromBill() {
        setAddOnRemoving(true);
        setAddOnRemoved(undefined);
        const reservationId = billLine.reservationId || billLine.reservedResource?.reservationId;
        const reservedResourceId = billLine.reservedResourceId || billLine.reservedResource?.reservedResourceId;
        if (reservationId && reservedResourceId) {
            removeReservedResourceFromReservation({ reservationId, reservedResourceId, billLine, cmsContext, setAddOnRemoved, setAddOnRemoving, dispatchAction });
        }
    }

    if (!widgetOptions.removeChoice) {
        return null;
    }

    return (
        <Modal isOpen={isModalOpen} toggle={toggleModal} size="s" className={"result-panel-button help-modal special-modal no-review"}>
            <ModalHeader toggle={toggleModal}>
                {modalType === "removableModal"
                    ? getI18nLocaleString(namespaceList.customerBillWidget, "removeAddOnBill", cmsContext.currentLocale, cmsContext.site).replace("x", billLine.resourceName)
                    : getI18nLocaleString(namespaceList.customerBillWidget, "updateAddOnBill", cmsContext.currentLocale, cmsContext.site).replace("x", billLine.resourceName)}
            </ModalHeader>
            <ModalBody>
                <div className="update-bill-addon-wrapper">
                    <div className="edit-image-loader">
                        {(addOnUpdating || addOnRemoving) && (
                            <React.Fragment>
                                <div className="edit-image-overlay" />
                                <div className="loader-wrapper">
                                    <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />
                                </div>
                            </React.Fragment>
                        )}
                        {modalType === "removableModal" ? (
                            <React.Fragment>
                                {oneDayOrLessRemainingBeforeArrival && (
                                    <span className="less-time-remaining-message">
                                        {getI18nLocaleString(namespaceList.customerBillWidget, "oneDayRemainsBeforeArrivalMessage", cmsContext.currentLocale, cmsContext.site)}
                                    </span>
                                )}
                                {!billLineReservedResource?.removable && !oneDayOrLessRemainingBeforeArrival && (
                                    <span className="remove-not-allowed-message">
                                        {getI18nLocaleString(namespaceList.customerBillWidget, "notAllowedRemoveMessage", cmsContext.currentLocale, cmsContext.site)}
                                    </span>
                                )}
                                {!oneDayOrLessRemainingBeforeArrival && billLineReservedResource?.removable && (
                                    <span className="remove-confirm-message">
                                        {getI18nLocaleString(namespaceList.customerBillWidget, "confirmRemoveMessage", cmsContext.currentLocale, cmsContext.site)}
                                    </span>
                                )}
                            </React.Fragment>
                        ) : (
                            <EditableModalBody
                                context={cmsContext}
                                addOnQuantity={addOnQuantity}
                                billLine={billLine}
                                setAddOnQuantity={setAddOnQuantity}
                                addOns={addOnsState.addOns}
                                oneDayOrLessRemainingBeforeArrival={oneDayOrLessRemainingBeforeArrival}
                            />
                        )}
                    </div>
                </div>
                <div className="alerts">
                    {addOnUpdated === SendingStatus.Success && (
                        <UncontrolledAlert color="success">{getI18nLocaleString(namespaceList.customerBillWidget, "addOnUpdated", cmsContext.currentLocale, cmsContext.site)}</UncontrolledAlert>
                    )}
                    {addOnUpdated === SendingStatus.Failed && (
                        <UncontrolledAlert color="danger">{getI18nLocaleString(namespaceList.customerBillWidget, "addOnFailedToUpdate", cmsContext.currentLocale, cmsContext.site)}</UncontrolledAlert>
                    )}
                    {addOnRemoved === SendingStatus.Success && (
                        <UncontrolledAlert color="success">{getI18nLocaleString(namespaceList.customerBillWidget, "addOnRemoved", cmsContext.currentLocale, cmsContext.site)}</UncontrolledAlert>
                    )}
                    {addOnRemoved === SendingStatus.Failed && (
                        <UncontrolledAlert color="danger">{getI18nLocaleString(namespaceList.customerBillWidget, "addOnFailedToRemove", cmsContext.currentLocale, cmsContext.site)}</UncontrolledAlert>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                {modalType === "editableModal" && (
                    <Button className="submit" disabled={!updationAllowed} color="primary" onClick={updateAddOnInsideBill}>
                        {getI18nLocaleString(namespaceList.widgetAdditions, "updateToBill", cmsContext.currentLocale, cmsContext.site)}
                    </Button>
                )}
                {modalType === "removableModal" && (
                    <Button className="submit" disabled={!removeAllowed} color="primary" onClick={removeAddOnFromBill}>
                        {getI18nLocaleString(namespaceList.widgetAdditions, "removeFromBill", cmsContext.currentLocale, cmsContext.site)}
                    </Button>
                )}
                <Button className="cancel" color="primary" onClick={toggleModal}>
                    <Close />
                    {getI18nLocaleString(namespaceList.admin, "cancel")}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const EditableModalBody = (props: {
    context: CMSProvidedProperties;
    billLine: BillLine;
    addOnQuantity: number;
    oneDayOrLessRemainingBeforeArrival: boolean;
    setAddOnQuantity: React.Dispatch<React.SetStateAction<number>>;
    addOns?: AddOn[];
}) => {
    const { context, billLine, addOnQuantity, setAddOnQuantity, addOns, oneDayOrLessRemainingBeforeArrival } = props;
    const { currentLocale, site } = context;
    const selectedAddOn: AddOn | undefined = addOns?.find((addOn) => addOn.resourceId === billLine.resourceId);
    const minQuantity = selectedAddOn?.minQuantity;
    const maxQuantity = selectedAddOn?.maxQuantity || selectedAddOn?.maxReservable;
    const dynamicFilter: DynamicFilter = useSelector((state: State) => state.dynamicFilter);
    const price = billLine.reservedResource?.price || 0;
    const totalPrice = (selectedAddOn?.price || price) * addOnQuantity;

    function handleAddOnQuantityChange(quantity: number) {
        setAddOnQuantity(quantity);
    }

    return (
        <div className="addon-wrapper">
            {oneDayOrLessRemainingBeforeArrival && (
                <span className="less-time-remaining-message">
                    <Alert color="warning">{getI18nLocaleString(namespaceList.customerBillWidget, "oneDayRemainsBeforeArrivalMessage", currentLocale, site)}</Alert>
                </span>
            )}
            <div className="addon-detail">
                <span className="addon-name">{selectedAddOn?.name}</span>
                <span className="addon-description">{selectedAddOn?.description}</span>
            </div>
            <div className="update-addon-quantity">
                <div className="quantity-spinner">
                    <span className="quantity-label">{getI18nLocaleString(namespaceList.admin, "quantity", currentLocale, site)}</span>
                    <SpinnerInput value={addOnQuantity} minValue={minQuantity || 0} maxValue={maxQuantity || 0} onChange={(quantity: number) => handleAddOnQuantityChange(quantity)}></SpinnerInput>
                </div>
                <span className="multiply-sign">×</span>
                <div className="price-per-quantity">
                    <span className="price-per-quantity-label">{getI18nLocaleString(namespaceList.customerBillWidget, "pricePerQuantity", currentLocale, site)}</span>
                    <span className="price">
                        {NumberUtil.priceWithCurrency({
                            price: selectedAddOn?.price || price,
                            context,
                            currencyCode: dynamicFilter?.currency?.code,
                        })}
                    </span>
                </div>
                <span className="equal-to-sign">=</span>
                <div className="total-price">
                    <span className="total-price-label">{getI18nLocaleString(namespaceList.customerBillWidget, "total", currentLocale, site)}</span>
                    <span className="price">
                        {NumberUtil.priceWithCurrency({
                            price: totalPrice,
                            context,
                            currencyCode: dynamicFilter?.currency?.code,
                        })}
                    </span>
                </div>
            </div>
        </div>
    );
};

const updateReservationReservedResource = async (props: {
    reservationId: number;
    reservedResourceId: number;
    addOnQuantity: number;
    billLine: BillLine;
    cmsContext: CMSProviderProperties;
    setAddOnUpdated: React.Dispatch<React.SetStateAction<SendingStatus | undefined>>;
    setAddOnUpdating: React.Dispatch<React.SetStateAction<boolean>>;
    dispatchAction: React.Dispatch<AnyAction>;
}) => {
    const { addOnQuantity, billLine, cmsContext, reservationId, reservedResourceId, dispatchAction, setAddOnUpdated, setAddOnUpdating } = props;
    const env = await getMxtsEnv(cmsContext);
    await MxtsApi.updateReservedResource(
        env,
        {
            quantity: addOnQuantity,
            type: billLine.resourceType,
        },
        [
            { key: "reservationId", value: reservationId },
            { key: "reservedResourceId", value: reservedResourceId },
        ]
    )
        .then((reservedResource) => {
            setAddOnUpdated(SendingStatus.Success);
            const action: SetReservationAction = {
                payload: { reservationId },
                type: ActionType.SetReservationState,
            };
            dispatchAction(action);
            setAddOnUpdating(false);
            return reservedResource;
        })
        .catch(() => {
            setAddOnUpdated(SendingStatus.Failed);
            setAddOnUpdating(false);
        });
};

const removeReservedResourceFromReservation = async (props: {
    reservationId: number;
    reservedResourceId: number;
    billLine: BillLine;
    cmsContext: CMSProviderProperties;
    setAddOnRemoved: React.Dispatch<React.SetStateAction<SendingStatus | undefined>>;
    setAddOnRemoving: React.Dispatch<React.SetStateAction<boolean>>;
    dispatchAction: React.Dispatch<AnyAction>;
}) => {
    const { dispatchAction, cmsContext, reservationId, reservedResourceId, setAddOnRemoved, setAddOnRemoving } = props;
    const env = await getMxtsEnv(cmsContext);
    await MxtsApi.removeReservedResource(env, {}, [
        { key: "reservationId", value: reservationId },
        { key: "reservedResourceId", value: reservedResourceId },
    ])
        .then((reservedResource) => {
            setAddOnRemoved(SendingStatus.Success);
            const action: SetReservationAction = {
                payload: { reservationId },
                type: ActionType.SetReservationState,
            };
            dispatchAction(action);
            setAddOnRemoving(false);
            return reservedResource;
        })
        .catch(() => {
            setAddOnRemoved(SendingStatus.Failed);
            setAddOnRemoving(false);
        });
};
