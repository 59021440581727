import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as moment from "moment";

import { Action, ActionType } from "../../../redux/actions";
import { AdditionAction, AdditionActionType } from "../../../redux/actions/additionAction";
import { BillAction, BillActionType } from "../../../redux/actions/billAction";
import { BillLine, BillLineType, ChoiceReservedResource, ChoiceResourceType, PriceEngineState, Resource } from "@maxxton/cms-mxts-api";
import { CustomerBillBaseProps, CustomerBillDispatchProps, CustomerBillProps, CustomerBillState, CustomerBillStoreProps } from "./bill.types";
import { UrlLinkParams, UrlParamsUtil } from "../../../utils/urlparam.util";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import {
    fetchBillForActivityPlanner,
    getAccoBillLines,
    getActivityBillLines,
    getAddOnBillLines,
    getAdditionBillLines,
    getBillChoice,
    mergeBillLinesByType,
    sortBillLinesForMultipleAccos,
} from "../../../utils/bill.util";
import { getFlattenedMyEnvData, getSelectedReservationId } from "../../../redux/reducers/myEnv/myEnv.util";
import { getI18nLocaleString, wrapProps } from "../../../i18n";
import { getLinkFromLinkingSpec, getUrlWithAnchor } from "../../../utils/linking.util";
import { isClientLoggedIn, setOpacityOnHide } from "../../../components/utils";

import { BillChoice } from "../../../redux/reducers/billReducer";
import { ContextUtil } from "../../../utils/context.util";
import { DATE_FORMAT } from "../../../utils/constants";
import { DateUtil } from "../../../utils/date.util";
import { Dispatch } from "redux";
import { DomainObjectUtil } from "../../../utils/domainobject.util";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { SelectedAddOn } from "../../../redux/reducers/add-ons/selectedAddOnsReducer";
import { SelectedAddition } from "../additions/additions.types";
import { State } from "../../../redux";
import { SubjectUtil } from "../subject/SubjectUtil";
import { billBody } from "./BillBody";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { fetchCurrencyForDC } from "../../../utils/currency.util";
import { fetchPricesForAvailableDates } from "../../../components/datepicker/datepicker.util";
import { getDynamicBookLink } from "../../resultsPanel/resultsPanelUtil";
import { getMxtsEnv } from "../../mxts";
import { getSelectedAddOnsInsideCart } from "../add-ons/addOns.util";
import namespaceList from "../../../i18n/namespaceList";
import { scroller } from "react-scroll";

class CustomerBillBase extends React.Component<CustomerBillProps, CustomerBillState> {
    // For layout type = Results, we are not fetching bill on mount, but by clicking an icon for Eg. ?
    constructor(props: CustomerBillProps) {
        super(props);
        this.state = {
            disableWidget: true,
            priceInfoModalOpen: false,
            priceBreakDownModalOpen: false,
            currencySymbol: "",
            isBillLoading: false,
        };
    }

    public componentDidMount() {
        const { dynamicFilter, context, dispatch, options, useCrpProps, accommodationType, unit, myEnvState, isMyEnvWidget } = this.props;
        if (dynamicFilter.currentLocale && context.currentLocale.code !== dynamicFilter.currentLocale) {
            const action: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.currentLocale,
                payload: {
                    currentLocale: context.currentLocale.code,
                },
            };
            dispatch(action);
            this.fetchBill();
        }

        this.setState({ disableWidget: !isClientLoggedIn() });

        const billChoice = this.getCurrentBillChoice();
        const startDate = dynamicFilter.startdate || accommodationType?.arrivalDate || unit?.date || unit?.arrivalDate;
        const endDate = dynamicFilter.enddate || accommodationType?.departureDate || unit?.departureDate || unit?.duration?.[0];
        if (!billChoice.customerBill.length || useCrpProps || (options.useAsAgentBill && !billChoice.agentBill.length)) {
            if ((startDate && endDate && (dynamicFilter.subject || options.useForActivityPlanner)) || this.getReservationId()) {
                this.fetchBill();
            }
        }

        if (isMyEnvWidget && billChoice.customerBill.length && (myEnvState.ownerState?.ownerOwnUnitLink || myEnvState.ownerState?.ownerOwnUnitLinkForGuest)) {
            this.fetchBill();
        }

        this.transformBillAccommodationBillLines(this.props);
        this.transformAdditionBillLines(this.props);
        this.transformAddOnBillLines(this.props);
        if (
            options.typeOfBillLines.some((typeOfBillLine) => typeOfBillLine.value === BillLineType.MY_ENV_RESERVATION_ADDON) &&
            options.typeOfBillLines.some((typeOfBillLine) => typeOfBillLine.value !== BillLineType.ADDON)
        ) {
            this.transformMyEnvReservationAddOnBillLines(this.props);
        }
        this.transformActivityBillLines(this.props);

        this.makeSureSubjectsAreNotCategories();
        if (options.removeUnitPreference) {
            this.getAccotypeObjectPrefId();
        }
        this.fetchCurrency(this.props);
    }

    public async componentDidUpdate(prevProps: CustomerBillProps, prevState: CustomerBillState) {
        const { billState, myEnvState } = prevProps;
        const { startdate, enddate, subject, activityTicketQuantity, resourceActivityDetailsIds, selectedActivities } = prevProps.dynamicFilter;
        const {
            startdate: nextStartdate,
            enddate: nextEnddate,
            subject: nextSubject,
            resourceActivityDetailsIds: nextResourceActivityDetailsIds,
            activityTicketQuantity: nextActivityTicketQuantity,
            selectedActivities: nextSelectedActivities,
            showMainActivityOnPage,
        } = this.props.dynamicFilter;
        const { accommodationType, unit } = this.props;

        if (!accommodationType || !unit) {
            if (
                startdate !== nextStartdate ||
                enddate !== nextEnddate ||
                subject !== nextSubject ||
                !isEqual(resourceActivityDetailsIds, nextResourceActivityDetailsIds) ||
                activityTicketQuantity !== nextActivityTicketQuantity ||
                !isEqual(selectedActivities, nextSelectedActivities)
            ) {
                if (!nextStartdate || !nextEnddate || (!nextSubject?.size && !nextActivityTicketQuantity && !nextSelectedActivities?.length)) {
                    if (!isEmpty(billState)) {
                        this.removeBill();
                    }
                } else if (showMainActivityOnPage && nextSelectedActivities?.length && !nextResourceActivityDetailsIds?.length) {
                    this.removeBill();
                } else {
                    this.fetchBill();
                }
            }
        }
        if (this.isBillUpdateNeeded(prevProps, this.props)) {
            this.fetchBill();
        }
        if (!!prevProps.dynamicFilter.unitPreference !== !!this.props.dynamicFilter.unitPreference) {
            this.fetchBill();
        } else if (this.props.dynamicFilter.unitid && !this.props.dynamicFilter.unitPreference && (await ContextUtil.shouldAlwaysAddUnitPreferenceCost(this.props.context))) {
            const action: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.unitPreference,
                payload: {
                    unitid: this.props.dynamicFilter.unitid,
                    unitPreference: true,
                },
            };
            this.props.dispatch(action);
        }

        if (myEnvState.confirmingShoppingCart && !this.props.myEnvState.confirmingShoppingCart) {
            if (this.props.billState.myEnvAdditionsBill?.error) {
                this.confirmShoppingCartAlert(false);
            } else {
                this.handleBillLinking();
                this.confirmShoppingCartAlert(true);
            }
        }

        const cartReservedResourceId: number | undefined = this.getCartReservedResourceId(this.props);
        if (prevState.cartReservedResourceId !== cartReservedResourceId && cartReservedResourceId !== undefined) {
            this.setState({ cartReservedResourceId });
        }
    }

    private isBillUpdateNeeded(prevProps: CustomerBillProps, props: CustomerBillProps): boolean {
        return (
            (!prevProps.dynamicFilter.unitPreference && props.dynamicFilter.unitPreference) ||
            !isEqual(prevProps.dynamicFilter.resourceActivityDetailsId, props.dynamicFilter.resourceActivityDetailsId) ||
            !isEqual(prevProps.dynamicFilter.resourceActivityDetailsIds, props.dynamicFilter.resourceActivityDetailsIds) ||
            !isEqual(prevProps.dynamicFilter.selectedActivities, props.dynamicFilter.selectedActivities) ||
            !isEqual(prevProps.dynamicFilter.voucherCodes, props.dynamicFilter.voucherCodes) ||
            (!!props.isMyEnvWidget &&
                getFlattenedMyEnvData(prevProps.myEnvState, prevProps.dynamicFilter).reservationId !== getFlattenedMyEnvData(props.myEnvState, props.dynamicFilter).reservationId) ||
            (!!props.isMyEnvWidget && !this.billReservationMatchesMyEnvReservation()) ||
            (!!props.isMyEnvWidget && !isEqual(prevProps.addOnsState.addOns, props.addOnsState.addOns)) ||
            (!!props.isMyEnvWidget && !isEqual(prevProps.reservationState.reservedResource, props.reservationState.reservedResource)) ||
            (!!props.isMyEnvWidget && !isEqual(prevProps.myEnvState.ownerState, props.myEnvState.ownerState))
        );
    }

    private billReservationMatchesMyEnvReservation() {
        const myEnvReservationId = this.props.myEnvState.selectedReservation?.reservation.reservationId;
        const billChoice = this.getCurrentBillChoice(this.props);
        const reservedResources = billChoice.reservedResources;
        if (reservedResources.length && myEnvReservationId && !billChoice.fetching) {
            const reservationId = reservedResources.find((reservedResource) => reservedResource.type === ChoiceResourceType.ACCOMMODATIONTYPE)?.reservationId;
            if (reservationId) {
                return myEnvReservationId === reservationId;
            }
        }
        return true;
    }

    public UNSAFE_componentWillReceiveProps(nextProps: CustomerBillProps) {
        this.transformBillAccommodationBillLines(nextProps);
        this.transformAdditionBillLines(nextProps);
        const billLines = this.props.options.useAsAgentBill
            ? this.getCurrentBillChoice(nextProps).agentBill
            : this.getCurrentBillChoice(nextProps).customerBill.filter((item) => item.payerType !== "AGENT");
        const prevAdditionBillLines = getAdditionBillLines(billLines, this.props.additionState);
        const nextAdditionBillLines = getAdditionBillLines(billLines, nextProps.additionState);
        if (prevAdditionBillLines.length && !nextAdditionBillLines.length) {
            this.setState({ transformedAdditionBillLines: [] });
        }
        this.transformAddOnBillLines(nextProps);
        if (
            this.props.options.typeOfBillLines.some((typeOfBillLine) => typeOfBillLine.value === BillLineType.MY_ENV_RESERVATION_ADDON) &&
            this.props.options.typeOfBillLines.some((typeOfBillLine) => typeOfBillLine.value !== BillLineType.ADDON)
        ) {
            this.transformMyEnvReservationAddOnBillLines(nextProps);
        }
        this.transformActivityBillLines(nextProps);
        if (!isEqual(this.props.reservationState, nextProps.reservationState) || !isEqual(this.props.instalmentsState, nextProps.instalmentsState)) {
            this.fetchCurrency(nextProps);
        }
    }

    public render(): JSX.Element | null {
        const { accommodationType, options, unit } = this.props;
        const billChoice = this.getCurrentBillChoice();
        // Widget with custom results panel
        const actions = {
            handlePriceModal: this.handlePriceModal,
            removeBillLine: this.removeBillLine,
            removeAddon: this.removeAddon,
            removePreferenceCost: this.removePreferenceCost,
            handlePriceBreakDownModal: this.handlePriceBreakDownModal,
        };
        const bill = billBody({ ...this.props }, this.state, actions, billChoice);
        const hideWidget = setOpacityOnHide(this.props.options);
        const billLines = options.useAsAgentBill ? billChoice.agentBill : billChoice.customerBill.filter((item) => item.payerType !== "AGENT");
        if (accommodationType || unit) {
            return (
                <div className={`display-price ${hideWidget}`}>
                    <FontAwesome name="question-circle" onClick={this.fetchBill} />
                    {billLines.length && bill}
                </div>
            );
        }
        // The widget can be standalone
        if (billLines.length) {
            return bill;
        }
        return <div />;
    }

    private getCurrentBillChoice(props: CustomerBillProps = this.props): BillChoice {
        const {
            billState,
            options: { targetBill },
        } = props;
        return getBillChoice(billState, targetBill);
    }

    private handlePriceModal = () => {
        this.setState({ priceInfoModalOpen: !this.state.priceInfoModalOpen });
    };

    private handlePriceBreakDownModal = () => {
        this.setState({ priceBreakDownModalOpen: !this.state.priceBreakDownModalOpen });
        if (!this.state.priceBreakDownModalOpen) {
            this.transformBreakDownPrices();
        }
    };

    private transformBreakDownPrices = async () => {
        const { context, dynamicFilter } = this.props;
        const accoReservation = this.state.transformedBillAccommodationBillLines?.filter((billLine) => billLine?.reservedResource?.type === ChoiceResourceType.ACCOMMODATIONTYPE)?.[0];
        if (accoReservation?.startDate && accoReservation?.endDate) {
            let arrivalDate = moment(accoReservation.startDate);
            let endMoment = moment(accoReservation.endDate);
            const pricesWithDuration = await fetchPricesForAvailableDates(arrivalDate, undefined, context, dynamicFilter);
            arrivalDate = arrivalDate.subtract(1, "day");
            endMoment = endMoment.subtract(1, "day");

            const transformedPrices = pricesWithDuration
                ?.filter((item) => {
                    const arrivalMoment = moment(item.arrivalDate);
                    return arrivalMoment.isSameOrAfter(arrivalDate) && arrivalMoment.isSameOrBefore(endMoment);
                })
                .map((item) => ({
                    arrivalDate: moment(item.arrivalDate).format(DATE_FORMAT.US_LONG_DATE),
                    prices: item.durations?.[0]?.resourcePrice?.prices?.[0] || {},
                }))
                .sort((a, b) => moment(a.arrivalDate).diff(moment(b.arrivalDate)));

            this.setState({ pricesWithDuration: transformedPrices });
        }
    };
    private fetchBill = async () => {
        if (this.props.dynamicFilter.useSubjectCategory) {
            // No use in obtaining the bill with invalid subjects
            return;
        }

        const {
            dynamicFilter,
            unit,
            accommodationType,
            options: { targetBill, useForActivityPlanner },
            rateTypeId,
            dispatch,
            context,
        } = this.props;
        /* Rate types (for a DC) will be considered first from the Widget options, then Site settings and
       last fallback is from the accomodation type itself */
        const applicableRateType = rateTypeId || dynamicFilter.rateType?.rateTypeId || accommodationType?.rateTypeId || unit?.rateTypeId;
        const env = await getMxtsEnv(context, dynamicFilter.currentLocale);
        if (dynamicFilter.offerSearchCode) {
            const specialSearchCode = dynamicFilter.offerSearchCode;
            const fetchBillAction = {
                type: ActionType.ChoiceAndPrice,
                targetBill,
                payload: { accommodationType, unit, applicableRateType, env, reservationId: this.getReservationId(), specialSearchCode },
            };
            dispatch(fetchBillAction);
        } else if (useForActivityPlanner) {
            if (dynamicFilter.resourceActivityDetailsId || dynamicFilter.resourceActivityDetailsIds?.length) {
                if (!dynamicFilter.selectedActivities?.length) {
                    const resourceActivityDetailsIds =
                        (dynamicFilter.resourceActivityDetailsId && [dynamicFilter.resourceActivityDetailsId]) ||
                        (dynamicFilter.resourceActivityDetailsIds?.length && dynamicFilter.resourceActivityDetailsIds) ||
                        [];
                    // We have added a dynamicFilter.reservationId check, This will set the selectedActivities once we have the reservation or reservationId.
                    if (!dynamicFilter.selectedActivities && resourceActivityDetailsIds.length && dynamicFilter.reservationId) {
                        const selectedResourceActivities = await context.mxtsApi.getResourceActivitiesDetails(env, { resourceActivityDetailsIds }).then((result) => result.content);

                        const action: FilterChangeAction = {
                            type: ActionType.FilterChange,
                            filter: dynamicFilterType.setSelectedActivities,
                            payload: { selectedActivities: selectedResourceActivities },
                        };
                        dispatch(action);
                    }
                }

                this.setState({ isBillLoading: true });
                const billChoice = await fetchBillForActivityPlanner(dynamicFilter, env, context);
                if (billChoice) {
                    const generateActivityBillAction = {
                        type: ActionType.Bill,
                        actionType: BillActionType.fetched,
                        payload: billChoice,
                    };
                    dispatch(generateActivityBillAction);
                }
                this.setState({ isBillLoading: false });
            }
        } else {
            const fetchBillAction = {
                type: ActionType.ChoiceAndPrice,
                targetBill,
                payload: { accommodationType, unit, applicableRateType, env, reservationId: this.getReservationId() },
            };
            dispatch(fetchBillAction);
        }
    };

    private removeBill = () => {
        const {
            dispatch,
            options: { targetBill },
        } = this.props;
        dispatch({ type: ActionType.Bill, actionType: BillActionType.remove, targetBill } as BillAction);
    };

    private removeBillLine = (resourceId: number, index: number, checkRemovable?: boolean) => {
        const billChoice = this.getCurrentBillChoice();
        if (checkRemovable && billChoice.reservedResources[0]) {
            let foundResource = billChoice.reservedResources[0].children.find((res: any) => res.resourceId === resourceId);
            if (!foundResource) {
                billChoice.reservedResources[0].children.forEach((child: any) => {
                    if (child.children) {
                        foundResource = child.children.find((ch: any) => ch.resourceId === resourceId);
                    }
                });
            }
            return foundResource && foundResource.removable;
        } else if (billChoice.reservedResources.length) {
            // eslint-disable-next-line no-console
            console.error("removeBillLine feature not implemented..");
            return false;
        }
    };

    private removePreferenceCost = () => {
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.unitPreference,
            payload: {},
        };
        this.props.dispatch(action);
    };

    private removeAddon = (billLine: BillLine): void => {
        const {
            dispatch,
            options: { targetBill },
        } = this.props;
        const { cashflowruleName, description, reservedResource, resourceId, resourceName, total, value } = billLine;
        const addonId = resourceId || reservedResource?.resourceId;
        if (addonId) {
            const addition = {
                [addonId]: ({
                    quantity: 0,
                    showRemoveButton: false,
                    updateAdditions: true,
                    price: reservedResource?.price || total || value,
                } as any) as SelectedAddition,
            };
            const action: AdditionAction = {
                type: ActionType.Addition,
                actionType: AdditionActionType.update,
                targetBill,
                payload: { updatedAddition: addition },
            };
            dispatch(action);
            this.showAdditionRemovedAlert(resourceName || cashflowruleName || description);
        }
    };

    private showAdditionRemovedAlert = (additionName: string): void => {
        const { alerts, currentLocale, site } = this.props.context;
        alerts.push({
            color: "success",
            message: `${additionName} ${getI18nLocaleString(namespaceList.widgetAdditions, "removedFromBill", currentLocale, site)}`,
        });
    };

    private confirmShoppingCartAlert = (success: boolean): void => {
        const { alerts, currentLocale, site } = this.props.context;
        const alertActive = alerts.alerts.find((alert) => alert.active);
        if (!alertActive) {
            alerts.push({
                color: success ? "success" : "danger",
                message: getI18nLocaleString(namespaceList.customerBillWidget, success ? "shoppingCartConfirmed" : "shoppingCartFailed", currentLocale, site),
            });
        }
    };

    private handleBillLinking = async () => {
        const { linking, useAsDynamicBookingUrlLink } = this.props.options;
        const { dynamicFilter, context, accommodationType, unit } = this.props;
        const params: UrlLinkParams = dynamicFilter ? UrlParamsUtil.getUrlParamsFromFilter(dynamicFilter) : {};
        const resourceId =
            Array.isArray(dynamicFilter.resourceid) && dynamicFilter.resourceid.length === 1
                ? dynamicFilter.resourceid[0]
                : !Array.isArray(dynamicFilter.resourceid)
                ? dynamicFilter.resourceid
                : undefined;
        const addResourceIdParams = resourceId || accommodationType?.resourceId || unit?.resourceId;
        const addUnitIdParams = dynamicFilter?.unitid || unit?.unitId;
        const link = await getLinkFromLinkingSpec({ context, linkingSpecOptions: linking });
        const dynamicBookUrl = getDynamicBookLink(params, link, addResourceIdParams, addUnitIdParams);
        const linkUrl = (useAsDynamicBookingUrlLink && dynamicBookUrl) || getUrlWithAnchor(link);
        const siteId = linking?.localizedLinkButtonOptions?.find((localized) => localized.locale === context.currentLocale.locale)?.siteId;
        const pageId = linking?.localizedLinkButtonOptions?.find((localized) => localized.locale === context.currentLocale.locale)?.pageId;

        if (siteId && pageId) {
            if (link.target === "_blank") {
                const openNewTab: Window | null = window.open("about:blank", "_blank");
                openNewTab!.location.href = linkUrl;
            } else {
                window.location.href = linkUrl;
            }
        }
        if (linking?.anchorLink && linking?.useAnchorLink && !link.url) {
            scroller.scrollTo(linking.anchorLink, {
                smooth: true,
                offset: -50,
                duration: 1700,
                delay: 300,
            });
        }
    };

    private transformActivityBillLines(nextProps: CustomerBillProps) {
        const activityBillLines: BillLine[] = getActivityBillLines({ customerBill: this.getCurrentBillChoice(nextProps).customerBill }).filter(
            (activityBillLine) => activityBillLine.quantity && activityBillLine.multiplier
        );
        this.setState({ transformedActivityBillLines: activityBillLines });
    }

    private transformAdditionBillLines(nextProps: CustomerBillProps) {
        const billLines = this.props.options.useAsAgentBill
            ? this.getCurrentBillChoice(nextProps).agentBill
            : this.getCurrentBillChoice(nextProps).customerBill.filter((item) => item.payerType !== "AGENT");
        const additionBillLines = getAdditionBillLines(billLines, nextProps.additionState);
        if (!additionBillLines.length) {
            return;
        }

        const transformedAdditionBillLines: BillLine[] = [];
        this.removeDeletedStateBillLines(additionBillLines).forEach((additionBillLine: BillLine) => {
            let alreadyAddedAdditionLine = transformedAdditionBillLines.find(
                (transformedLine) => transformedLine.resourceId === additionBillLine.resourceId && (!additionBillLine.state || additionBillLine.state !== PriceEngineState.DELETED)
            );
            if (!alreadyAddedAdditionLine) {
                alreadyAddedAdditionLine = { ...additionBillLine };
                transformedAdditionBillLines.push(alreadyAddedAdditionLine);
            } else {
                alreadyAddedAdditionLine.total = (alreadyAddedAdditionLine.total || 0) + (additionBillLine.total || 0);
                alreadyAddedAdditionLine.totalExclusiveVat = (alreadyAddedAdditionLine.totalExclusiveVat || 0) + (additionBillLine.totalExclusiveVat || 0);
                const startDate = DateUtil.getEarliestDate([alreadyAddedAdditionLine, additionBillLine].filter((billLine) => billLine.startDate).map((billLine) => new Date(billLine.startDate!)));
                const endDate = DateUtil.getLatestDate([alreadyAddedAdditionLine, additionBillLine].filter((billLine) => billLine.endDate).map((billLine) => new Date(billLine.endDate!)));
                alreadyAddedAdditionLine.startDate = startDate || undefined;
                alreadyAddedAdditionLine.endDate = endDate || undefined;
                alreadyAddedAdditionLine.quantity += additionBillLine.quantity;
            }
        });
        this.setState({ transformedAdditionBillLines });
    }

    private async transformAddOnBillLines(nextProps: CustomerBillProps) {
        const { isMyEnvWidget, selectedAddOnsState, options } = nextProps;
        const { useAsShoppingCart, showIncludedAddOnsWithExtras, useAsAgentBill, showUnitPreferenceBillLineWithExtras } = options;
        const cartReservedResourceId: number | undefined = !this.state.cartReservedResourceId ? this.getCartReservedResourceId(nextProps) : this.state.cartReservedResourceId;
        const selectedAddOns: SelectedAddOn[] = getSelectedAddOnsInsideCart({ selectedAddOnsState, cartReservedResourceId });
        const transformedAddOnBillLines: BillLine[] = [];
        // MyEnv shopping cart
        // If we use the bill as a shopping cart we can simply take the resourceIds from the selected add-ons (which is the basket).
        // Existing add-ons have a state of UNCHANGED, so we are only looking for the ones with the ADDED state.
        if (useAsShoppingCart && isMyEnvWidget && this.billReservationMatchesMyEnvReservation()) {
            const addOnBillLines: BillLine[] = getAddOnBillLines({ customerBill: this.getCurrentBillChoice(nextProps).customerBill, selectedAddOns });
            selectedAddOns.forEach((addOn: SelectedAddOn) => {
                const cartAddOnBillLines: BillLine[] | undefined = addOnBillLines.filter((billLine: BillLine) => billLine.resourceId === addOn.resourceId && billLine.state === PriceEngineState.ADDED);
                if (cartAddOnBillLines) {
                    cartAddOnBillLines.map((billLine: BillLine) => {
                        const existingAddOnBillLine = transformedAddOnBillLines.find((transformedBillLine: BillLine) => transformedBillLine.resourceId === billLine.resourceId);
                        this.filterAndMergeAddOnBillLines(billLine, transformedAddOnBillLines, existingAddOnBillLine);
                    });
                }
            });
            // Bookings Module
            // Filter and merge the bill lines
        } else {
            const addOnBillLines: BillLine[] = getAddOnBillLines({ customerBill: this.getCurrentBillChoice(nextProps).customerBill, selectedAddOns });
            const filteredAddOnBillLines = this.removeDeletedStateBillLines(addOnBillLines);

            filteredAddOnBillLines.forEach((billLine: BillLine) => {
                const existingAddOnBillLine = transformedAddOnBillLines.find((transformedBillLine: BillLine) => transformedBillLine.resourceId === billLine.resourceId);
                this.filterAndMergeAddOnBillLines(billLine, transformedAddOnBillLines, existingAddOnBillLine);
            });
        }
        if (showIncludedAddOnsWithExtras || showUnitPreferenceBillLineWithExtras) {
            const billLines = useAsAgentBill ? this.getCurrentBillChoice(nextProps).agentBill : this.getCurrentBillChoice(nextProps).customerBill.filter((item) => item.payerType !== "AGENT");
            const accommodationBillLines = getAccoBillLines({ billLines, additionState: nextProps.additionState, selectedAddOns });
            if (showUnitPreferenceBillLineWithExtras) {
                const unitPreferenceBillLine = accommodationBillLines.filter(
                    (accoBillLine: BillLine) =>
                        accoBillLine.billLineType === BillLineType.RESERVED_RESOURCE &&
                        accoBillLine.resourceType === ChoiceResourceType.EXTRA &&
                        accoBillLine.reservedResource &&
                        !(accoBillLine.quantity > 1) &&
                        !(accoBillLine as any).allowMovingToMainBill
                );
                if (unitPreferenceBillLine.length) {
                    transformedAddOnBillLines.push(...unitPreferenceBillLine);
                }
            }
            if (showIncludedAddOnsWithExtras) {
                const includedAddonsBillLines = accommodationBillLines.filter(
                    (accoBillLine: BillLine) => accoBillLine.billLineType === BillLineType.RESERVED_RESOURCE && accoBillLine.resourceType === ChoiceResourceType.PRODUCTTYPE && accoBillLine.total === 0
                );
                if (includedAddonsBillLines) {
                    transformedAddOnBillLines.push(...includedAddonsBillLines);
                }
            }
        }
        this.setState({ transformedAddOnBillLines });
    }

    private async transformMyEnvReservationAddOnBillLines(nextProps: CustomerBillProps) {
        const { isMyEnvWidget, options, selectedAddOnsState, myEnvState, addOnsState } = nextProps;
        const { transformedBillAccommodationBillLines } = this.state;
        const billLines = options.useAsAgentBill ? this.getCurrentBillChoice(nextProps).agentBill : this.getCurrentBillChoice(nextProps).customerBill.filter((item) => item.payerType !== "AGENT");
        const selectedAddOns: SelectedAddOn[] = getSelectedAddOnsInsideCart({ selectedAddOnsState, cartReservedResourceId: this.state.cartReservedResourceId });
        const accoBillLines = getAccoBillLines({ billLines, additionState: nextProps.additionState, selectedAddOns });
        if (!accoBillLines.length) {
            return;
        }
        const addOns = addOnsState.addOns;
        let transformedMyEnvReservationAddOnBillLines: BillLine[] = this.removeDeletedStateBillLines(accoBillLines);
        if (isMyEnvWidget && this.billReservationMatchesMyEnvReservation() && transformedMyEnvReservationAddOnBillLines.length && addOns?.length) {
            const reservationDate = myEnvState.selectedReservation?.reservation.reservationDate;
            if (reservationDate && addOns?.length) {
                transformedMyEnvReservationAddOnBillLines = transformedMyEnvReservationAddOnBillLines.filter(
                    (transformedMyEnvReservationAddOnBillLine) =>
                        transformedMyEnvReservationAddOnBillLine.reservedResource?.reservationDate !== reservationDate &&
                        addOns.some((addOn) => addOn.resourceId === transformedMyEnvReservationAddOnBillLine.resourceId)
                );
            }
        } else {
            transformedMyEnvReservationAddOnBillLines = [];
        }
        if (transformedMyEnvReservationAddOnBillLines.length && transformedBillAccommodationBillLines?.length) {
            const filteredAccommodationBillLines = transformedBillAccommodationBillLines?.filter(
                (transformedBillAccommodationBillLine) =>
                    !transformedMyEnvReservationAddOnBillLines.some(
                        (transformedMyEnvReservationAddOnBillLine) => transformedMyEnvReservationAddOnBillLine.resourceId === transformedBillAccommodationBillLine.resourceId
                    )
            );
            this.setState({ transformedBillAccommodationBillLines: filteredAccommodationBillLines });
        }
        this.setState({ transformedAddOnBillLines: transformedMyEnvReservationAddOnBillLines });
    }

    private filterAndMergeAddOnBillLines(billLine: BillLine, transformedAddOnBillLines: BillLine[], existingAddOnBillLine?: BillLine) {
        if (
            (existingAddOnBillLine?.reservedResource?.compositionItemId ||
                existingAddOnBillLine?.reservedResource?.impliesId ||
                billLine?.reservedResource?.compositionItemId ||
                billLine?.reservedResource?.impliesId) &&
            !(existingAddOnBillLine?.resourceType === ChoiceResourceType.COMPOSITION)
        ) {
            return;
        }
        if (existingAddOnBillLine) {
            existingAddOnBillLine.total = (existingAddOnBillLine.total || 0) + (billLine.total || 0);
            existingAddOnBillLine.totalExclusiveVat = (existingAddOnBillLine.totalExclusiveVat || 0) + (billLine.totalExclusiveVat || 0);
            const startDate = DateUtil.getEarliestDate([existingAddOnBillLine, billLine].filter((billLine) => billLine.startDate).map((billLine) => new Date(billLine.startDate!)));
            const endDate = DateUtil.getLatestDate([existingAddOnBillLine, billLine].filter((billLine) => billLine.endDate).map((billLine) => new Date(billLine.endDate!)));
            existingAddOnBillLine.startDate = startDate || undefined;
            existingAddOnBillLine.endDate = endDate || undefined;
            existingAddOnBillLine.quantity += existingAddOnBillLine.quantity;
            if (existingAddOnBillLine.resourceType === ChoiceResourceType.COMPOSITION && existingAddOnBillLine.reservedResource) {
                existingAddOnBillLine.quantity = existingAddOnBillLine.reservedResource.quantity;
            }
        } else {
            transformedAddOnBillLines.push({ ...billLine });
        }
    }

    // eslint-disable-next-line max-lines-per-function
    private transformBillAccommodationBillLines(nextProps: CustomerBillProps) {
        const {
            selectedAddOnsState,
            options: { showSpecialSeparatelyAfterMerging, showOneSpecialPerLineAfterMerging, showIncludedAddOnsWithExtras, showUnitPreferenceBillLineWithExtras, showTitleForSpecials },
        } = nextProps;
        const selectedAddOns: SelectedAddOn[] = getSelectedAddOnsInsideCart({ selectedAddOnsState, cartReservedResourceId: this.state.cartReservedResourceId });
        const billLines = this.props.options.useAsAgentBill
            ? this.getCurrentBillChoice(nextProps).agentBill
            : this.getCurrentBillChoice(nextProps).customerBill.filter((item) => item.payerType !== "AGENT");

        const accoBillLines = getAccoBillLines({ billLines, additionState: nextProps.additionState, selectedAddOns });
        if (!accoBillLines.length) {
            return;
        }

        let transformedAccoLines = this.removeDeletedStateBillLines(accoBillLines);
        let accoSpecificLines: BillLine[];
        let filteredSpecials: BillLine[] = [];
        if (nextProps.options.mergeAccoBillLines) {
            if (showSpecialSeparatelyAfterMerging || showOneSpecialPerLineAfterMerging) {
                // Note:- filtered out only accommodation type not specials as here special will be separately calculated.
                accoSpecificLines = transformedAccoLines.filter(
                    (billLine: BillLine) => billLine.billLineType?.toUpperCase() === BillLineType.RESERVED_RESOURCE && billLine.resourceType?.toUpperCase() === ChoiceResourceType.ACCOMMODATIONTYPE
                );
                filteredSpecials = transformedAccoLines.filter(
                    (billLine: BillLine) => billLine.billLineType?.toUpperCase() === BillLineType.RESERVED_RESOURCE && billLine.resourceType?.toUpperCase() === ChoiceResourceType.SPECIAL
                );
            } else {
                accoSpecificLines = transformedAccoLines.filter(
                    (billLine: BillLine) =>
                        billLine.billLineType?.toUpperCase() === BillLineType.RESERVED_RESOURCE &&
                        (billLine.resourceType?.toUpperCase() === ChoiceResourceType.ACCOMMODATIONTYPE || billLine.resourceType?.toUpperCase() === ChoiceResourceType.SPECIAL)
                );
            }
            const mergedProductTypes = this.mergeProductTypeBillLines(transformedAccoLines);
            if (accoSpecificLines.length > 1 || filteredSpecials.length > 1 || mergedProductTypes.isMultiProductTypeExist) {
                const startDate = DateUtil.getEarliestDate(accoSpecificLines.filter((billLine: BillLine) => !!billLine.startDate).map((billLine) => new Date(billLine.startDate!)));
                const endDate = DateUtil.getLatestDate(accoSpecificLines.filter((billLine: BillLine) => !!billLine.endDate).map((billLine) => new Date(billLine.endDate!)));
                const mergedAccoBillLine: BillLine = { ...accoSpecificLines.find((billLine: BillLine) => billLine.resourceType?.toUpperCase() === ChoiceResourceType.ACCOMMODATIONTYPE) } as BillLine;
                mergedAccoBillLine.total = 0;
                mergedAccoBillLine.totalExclusiveVat = 0;
                accoSpecificLines.forEach((billLine) => {
                    mergedAccoBillLine.total! += billLine.total || 0;
                    mergedAccoBillLine.totalExclusiveVat! += billLine.totalExclusiveVat || 0;
                });
                mergedAccoBillLine.startDate = startDate || undefined;
                mergedAccoBillLine.endDate = endDate || undefined;
                if (showSpecialSeparatelyAfterMerging || showOneSpecialPerLineAfterMerging) {
                    const mergedSpecial = { ...filteredSpecials.find((bilLine: BillLine) => bilLine.resourceType?.toUpperCase() === ChoiceResourceType.SPECIAL) } as BillLine;
                    if (!showOneSpecialPerLineAfterMerging) {
                        // Note:- merged all the specials and show it separately.
                        mergedSpecial.total = 0;
                        mergedSpecial.totalExclusiveVat = 0;
                        filteredSpecials.forEach((bilLine: BillLine) => {
                            mergedSpecial.total! += bilLine.total || 0;
                            mergedSpecial.totalExclusiveVat! += bilLine.totalExclusiveVat || 0;
                        });
                    }
                    transformedAccoLines = transformedAccoLines.filter(
                        (billLine: BillLine) =>
                            !(
                                billLine.billLineType?.toUpperCase() === BillLineType.RESERVED_RESOURCE &&
                                (billLine.resourceType?.toUpperCase() === ChoiceResourceType.ACCOMMODATIONTYPE || billLine.resourceType?.toUpperCase() === ChoiceResourceType.SPECIAL)
                            )
                    );
                    const arrayMethod = showTitleForSpecials ? "push" : "unshift";
                    if (showOneSpecialPerLineAfterMerging) {
                        transformedAccoLines[arrayMethod](...filteredSpecials);
                    } else {
                        transformedAccoLines[arrayMethod](mergedSpecial);
                    }
                } else {
                    transformedAccoLines = transformedAccoLines.filter(
                        (billLine: BillLine) =>
                            !(
                                billLine.billLineType?.toUpperCase() === BillLineType.RESERVED_RESOURCE &&
                                (billLine.resourceType?.toUpperCase() === ChoiceResourceType.ACCOMMODATIONTYPE || billLine.resourceType?.toUpperCase() === ChoiceResourceType.SPECIAL)
                            )
                    );
                }
                if (nextProps.options.overwriteMergedAccoBillLineName) {
                    const localeId = nextProps.context.currentLocale.locale;
                    const localContent = nextProps.options.localizedContent?.find((lc) => lc.locale === localeId);

                    if (accoSpecificLines.find((billLine: BillLine) => billLine.resourceType?.toUpperCase() === ChoiceResourceType.SPECIAL) && localContent?.accoBillLineInclSpecialName) {
                        mergedAccoBillLine.resourceName = localContent.accoBillLineInclSpecialName;
                    } else if (localContent?.accoBillLineName) {
                        mergedAccoBillLine.resourceName = localContent.accoBillLineName;
                    }
                }
                // Note: filter out the PRODUCTTYPE with same name as it is already merged in mergeProductTypeBillLines function.
                transformedAccoLines = transformedAccoLines.filter(
                    (billLine) =>
                        !(
                            billLine.billLineType?.toUpperCase() === BillLineType.RESERVED_RESOURCE &&
                            billLine.resourceType?.toUpperCase() === ChoiceResourceType.PRODUCTTYPE &&
                            mergedProductTypes.mergedProductTypeBillLines.find((productLine) => productLine.resourceName === billLine.resourceName)
                        )
                );

                transformedAccoLines.unshift(mergedAccoBillLine as BillLine, ...(mergedProductTypes.mergedProductTypeBillLines as BillLine[]));
            }
        }
        if (
            nextProps.options?.mergeResortBillLines &&
            !!nextProps.options.typeOfBillLines.length &&
            nextProps.options.typeOfBillLines.some((contentType) => contentType.value === BillLineType.RESORT_ARTICLE)
        ) {
            transformedAccoLines = mergeBillLinesByType(transformedAccoLines, BillLineType.RESORT_ARTICLE);
        }
        if (nextProps.options?.mergeTaxBillLines && !!nextProps.options.typeOfBillLines.length && nextProps.options.typeOfBillLines.some((contentType) => contentType.value === BillLineType.TAX)) {
            transformedAccoLines = mergeBillLinesByType(transformedAccoLines, BillLineType.TAX);
        }
        if (nextProps.options?.mergeVatBillLines && !!nextProps.options.typeOfBillLines.length && nextProps.options.typeOfBillLines.some((contentType) => contentType.value === BillLineType.VAT)) {
            transformedAccoLines = mergeBillLinesByType(transformedAccoLines, BillLineType.VAT);
        }
        if (nextProps.options.isBillLinesGrouped) {
            transformedAccoLines.sort((firstValue, secondValue) => secondValue.total - firstValue.total);
        }
        if (nextProps.options.useForActivityPlanner) {
            transformedAccoLines = transformedAccoLines?.filter(
                (billLine) =>
                    billLine.billLineType?.toUpperCase() === BillLineType.RESERVED_RESOURCE &&
                    !(billLine.resourceType?.toUpperCase() === ChoiceResourceType.RESOURCEACTIVITY || ChoiceResourceType.ACTIVITY)
            );
        }
        accoSpecificLines = transformedAccoLines?.filter(
            (billLine) => billLine.billLineType?.toUpperCase() === BillLineType.RESERVED_RESOURCE && billLine.resourceType?.toUpperCase() === ChoiceResourceType.ACCOMMODATIONTYPE
        );
        if (accoSpecificLines?.length > 1) {
            transformedAccoLines = sortBillLinesForMultipleAccos(transformedAccoLines);
        }
        if (showUnitPreferenceBillLineWithExtras) {
            transformedAccoLines = transformedAccoLines?.filter(
                (accoBillLine: BillLine) =>
                    !(
                        accoBillLine.billLineType === BillLineType.RESERVED_RESOURCE &&
                        accoBillLine.resourceType === ChoiceResourceType.EXTRA &&
                        accoBillLine.reservedResource &&
                        !(accoBillLine.quantity > 1) &&
                        !(accoBillLine as any).allowMovingToMainBill
                    )
            );
        }
        if (showIncludedAddOnsWithExtras) {
            transformedAccoLines = transformedAccoLines?.filter(
                (accoBillLine: BillLine) =>
                    !(
                        accoBillLine.billLineType === BillLineType.RESERVED_RESOURCE &&
                        ((accoBillLine.resourceType === ChoiceResourceType.PRODUCTTYPE && accoBillLine.total === 0) ||
                            (accoBillLine.resourceType === ChoiceResourceType.EXTRA && !(accoBillLine.quantity > 1) && !(accoBillLine as any).allowMovingToMainBill))
                    )
            );
        }
        this.setState({ transformedBillAccommodationBillLines: transformedAccoLines });
    }

    private mergeProductTypeBillLines = (transformedAccoLines: BillLine[]) => {
        // Merge add-ons implies in 1 line if the add-ons are the same.
        const mergedProductTypeBillLines: BillLine[] = [];
        const transformedAccoBillLines = cloneDeep(transformedAccoLines);
        let isMultiProductTypeExist = false;
        const { isMyEnvWidget } = this.props;
        if (!isMyEnvWidget) {
            transformedAccoBillLines.forEach((accoLine) => {
                const sameImpliesCount = transformedAccoBillLines.filter(
                    (billLine) =>
                        billLine.billLineType === BillLineType.RESERVED_RESOURCE && billLine.resourceType === ChoiceResourceType.PRODUCTTYPE && billLine.resourceName === accoLine.resourceName
                ).length;
                if (sameImpliesCount > 1 && !mergedProductTypeBillLines.find((productLine) => productLine.resourceName === accoLine.resourceName)) {
                    // When we have more then 1 add-ons implies with same name.
                    accoLine.total = 0;
                    accoLine.totalExclusiveVat = 0;
                    mergedProductTypeBillLines.push(accoLine);
                    isMultiProductTypeExist = true;
                }
            });
        }
        mergedProductTypeBillLines.forEach((productLine) => {
            transformedAccoLines.forEach((accoLine) => {
                if (accoLine.billLineType === BillLineType.RESERVED_RESOURCE && accoLine.resourceType === ChoiceResourceType.PRODUCTTYPE && accoLine.resourceName === productLine.resourceName) {
                    productLine.total! += accoLine.total || 0;
                    productLine.totalExclusiveVat! += accoLine.totalExclusiveVat || 0;
                }
            });
        });
        return { mergedProductTypeBillLines, isMultiProductTypeExist };
    };

    private async makeSureSubjectsAreNotCategories() {
        const { dynamicFilter, context } = this.props;
        if (dynamicFilter.useSubjectCategory && dynamicFilter.subject) {
            const env = await getMxtsEnv(context, dynamicFilter.currentLocale);
            await SubjectUtil.convertSubjectCategoriesBackToSubjects(context, dynamicFilter, env, dynamicFilter.subject, this.props.dispatch);
            this.fetchBill();
        }
    }

    private removeDeletedStateBillLines(billLines: BillLine[]): BillLine[] {
        return billLines.filter((billLine) => billLine.state !== PriceEngineState.DELETED) || [];
    }

    private getReservationId(): number | undefined {
        return getSelectedReservationId(this.props);
    }

    private getCartReservedResourceId(updatedProps: CustomerBillProps): number | undefined {
        const myEnvReservationId = updatedProps.myEnvState.selectedReservation?.reservation.reservationId;
        const billChoice = this.getCurrentBillChoice(updatedProps);
        const reservedResources = billChoice.reservedResources;
        if (reservedResources.length && myEnvReservationId && !billChoice.fetching) {
            const accoReservedResources: ChoiceReservedResource[] | undefined = reservedResources.filter((reservedResource) => reservedResource.type === ChoiceResourceType.ACCOMMODATIONTYPE);
            if (accoReservedResources.length === 1) {
                return accoReservedResources[0].reservedResourceId;
            } else if (accoReservedResources.length > 1) {
                const accoReservedResource = accoReservedResources.find((resource) => resource.resourceId === this.props.dynamicFilter.resourceid);

                return accoReservedResource?.reservedResourceId;
            }
        }
        return undefined;
    }

    private async getAccotypeObjectPrefId() {
        const { dynamicFilter, availabilityState, context } = this.props;
        const resourceId: number | undefined =
            dynamicFilter.resourceid || availabilityState.availabilityResult?.response.resources?.[0]?.resourceId || availabilityState.availabilityResult?.response.units?.[0]?.resourceId;
        const env = await getMxtsEnv(context, dynamicFilter.currentLocale);
        if (resourceId) {
            const resource: Resource | null = await DomainObjectUtil.getResourceById(context.mxtsApi, resourceId, env);
            this.setState({ accoTypeObjectPrefId: resource?.accotypeObjectPrefId });
        }
    }

    private fetchCurrency = async (props: CustomerBillProps) => {
        const distributionChannelId = props.reservationState.reservation?.distributionChannelId || props.instalmentsState.instalments?.[0].reservation?.distributionchannelId;
        let currencySymbol = props.dynamicFilter.currency?.symbol || "";
        if (distributionChannelId) {
            const currency = await fetchCurrencyForDC(props.context, distributionChannelId);
            currencySymbol = currency?.symbol || currencySymbol;
        }
        this.setState({ currencySymbol });
    };
}

function mapStateToProps(state: State) {
    return {
        myEnvState: state.myEnvState,
        dynamicFilter: state.dynamicFilter,
        billState: state.billState,
        availabilityState: state.availabilityState,
        additionState: state.additionState,
        selectedAddOnsState: state.selectedAddOnsState,
        reservationState: state.reservationState,
        instalmentsState: state.instalmentsState,
        addOnsState: state.addOnsState,
    };
}

// TODO: Everywhere where we use Dispatch<Action> we are missing one or more actions in our store. Hence we use the generic Action.
function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        dispatch,
    };
}

const CustomerBillWidget = connect<CustomerBillStoreProps, CustomerBillDispatchProps>(mapStateToProps, mapDispatchToProps)(CustomerBillBase);

export const DynamicCustomerBill = wrapProps<CustomerBillBaseProps>(CustomerBillWidget);
