import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import { BillLineType, ChoiceResourceType } from "@maxxton/cms-mxts-api";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { ExtraActivityInfo } from "./ExtraActivityInfo";
import { ExtraAddOnInfo } from "./ExtraAddOnInfo";
import { State } from "../../../../../redux";
import { TicketingTypes } from "../../../../page/activityPlanner/activityPlanner.enum";
import { useBillContext } from "../../BillContext";
import { useBillLineContext } from "../BillLineContext";
import { useSelector } from "react-redux";

export interface BillLineResourceDescriptionProps {
    showExtraAddOnInformation?: boolean;
}

export const BillLineResourceDescription: React.FC<BillLineResourceDescriptionProps> = (props: BillLineResourceDescriptionProps) => {
    const { showExtraAddOnInformation } = props;
    const { billLine } = useBillLineContext();
    const { widgetOptions } = useBillContext();
    const { selectedActivities, activityTicketQuantity, showMainActivityOnPage } = useSelector((state: State) => state.dynamicFilter);
    const currentActivity = selectedActivities?.find((activity) => activity.resourceActivityDetailsId === billLine.reservedResource?.resourceActivityDetailsId);

    const [showPriceModal, setShowPriceModal] = React.useState<boolean>(false);
    const togglePriceModal = () => setShowPriceModal(!showPriceModal);

    let resourceName: string | undefined;
    if (
        (widgetOptions.useConfiguredCashflowRuleName && widgetOptions.useCashflowruleNameForSpecial && billLine.resourceType === ChoiceResourceType.SPECIAL) ||
        (widgetOptions.useConfiguredCashflowRuleName && widgetOptions.useCashflowruleNameForAccoType && billLine.resourceType === ChoiceResourceType.ACCOMMODATIONTYPE)
    ) {
        resourceName = billLine.cashflowruleName;
    } else {
        resourceName = billLine.resourceName;
    }
    if (widgetOptions.useAccommodationCodeInBillLine) {
        resourceName = `${resourceName} (${billLine.reservedResource?.code}) `;
    }
    let quantityOrMultiplier = "";
    if (billLine.multiplier) {
        if (billLine.multiplier > billLine.quantity) {
            const ticketingType = currentActivity?.resourceActivity.resortActivity.ticketingType;
            quantityOrMultiplier =
                (ticketingType === TicketingTypes.TICKETS || ticketingType === TicketingTypes.TICKET_PER_GROUP) && !showMainActivityOnPage && !activityTicketQuantity ? "" : `${billLine.multiplier}x `;
        } else {
            quantityOrMultiplier = `${billLine.quantity}x `;
        }
    }
    return (
        <React.Fragment>
            {widgetOptions.quantity && <td className="counts">{quantityOrMultiplier}</td>}
            <td className={`desc ${billLine.billLineType}`}>
                {resourceName || billLine.description}
                {widgetOptions.showDescriptionForChoice &&
                    (billLine.resourceName || billLine.resourceDescription) &&
                    billLine.resourceType !== ChoiceResourceType.ACCOMMODATIONTYPE &&
                    billLine.billLineType !== BillLineType.TOTAL &&
                    billLine.billLineType !== BillLineType.SUBTOTAL && (
                        <span onClick={togglePriceModal} className={`ml-2 addition__product ${widgetOptions.priceInfoText === "modal" ? "info-modal" : "pop-over-link info-hover"}`}>
                            <span className={widgetOptions.priceInfoText === "modal" ? "info-modal" : "info-hover"}>
                                <FontAwesome name="info-circle" />
                            </span>
                            {widgetOptions.priceInfoText === "modal" ? (
                                <Modal isOpen={showPriceModal} toggle={togglePriceModal} size="s" className="bill-info-modal">
                                    <ModalHeader tag="h4" toggle={togglePriceModal} className="no-background" />
                                    <ModalBody>
                                        <div>{billLine.resourceDescription || resourceName}</div>
                                    </ModalBody>
                                </Modal>
                            ) : (
                                resourceName && <div className="pop-over-content bill-info-hover">{billLine.resourceDescription || resourceName}</div>
                            )}
                        </span>
                    )}
                {showExtraAddOnInformation && <ExtraAddOnInfo />}
                {billLine?.resourceType === ChoiceResourceType.RESOURCEACTIVITY && showExtraAddOnInformation && <ExtraActivityInfo billLine={billLine} />}
            </td>
        </React.Fragment>
    );
};
