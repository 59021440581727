import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import { useDispatch, useSelector } from "react-redux";

import { State } from "../../../../../redux";
import { clearAddOnSelection } from "../../../add-ons/add-on/addOn.util";
import { startUpdateSelectedAddOnsSaga } from "../../../../../redux/actions/add-ons/addOnsAction";
import { useBillContext } from "../../BillContext";
import { useBillLineContext } from "../BillLineContext";

export interface AddOnRemoveButtonProps {
    removePreferenceCost?: () => void;
}

export const AddOnRemoveButton: React.FC<AddOnRemoveButtonProps> = (props: AddOnRemoveButtonProps) => {
    const { addOn } = useBillLineContext();
    const { cartReservedResourceId, cmsContext, widgetOptions } = useBillContext();
    const { removePreferenceCost } = props;

    const dispatch = useDispatch();

    const myEnvState = useSelector((state: State) => state.myEnvState);

    const removeAddOn = () => {
        if (addOn) {
            dispatch(startUpdateSelectedAddOnsSaga({ updatedAddOn: clearAddOnSelection(addOn), cartReservedResourceId, targetBill: widgetOptions.targetBill, cmsContext }));
        } else if (widgetOptions.showUnitPreferenceBillLineWithExtras && removePreferenceCost) {
            removePreferenceCost();
        }
    };

    if (!widgetOptions.removeChoice) {
        return null;
    }

    return (
        <td className="add-on-removable" onClick={removeAddOn}>
            <FontAwesome className="remove-icon" name="close" />
        </td>
    );
};
