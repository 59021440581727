import * as React from "react";
import * as moment from "moment";

import { DateSubjectSelection, SelectedAddOn } from "../../../../../redux/reducers/add-ons/selectedAddOnsReducer";

import { AddOnSubject } from "../../../add-ons/add-on/info-modal/modal-types/modalData.util";
import { DATE_FORMAT } from "../../../../../utils/constants";
import { DisplayType } from "../../../add-ons/add-on/addOn.util";
import { State } from "../../../../../redux";
import { getI18nLocaleString } from "../../../../../i18n";
import namespaceList from "../../../../../i18n/namespaceList";
import { useBillContext } from "../../BillContext";
import { useBillLineContext } from "../BillLineContext";
import { useSelector } from "react-redux";

export const ExtraAddOnInfo: React.FC = (): JSX.Element | null => {
    const { addOn } = useBillLineContext();
    const {
        cmsContext: { currentLocale, site },
    } = useBillContext();
    const { startdate: reservationStartDate, enddate: reservationEndDate } = useSelector((state: State) => state.dynamicFilter);
    const addOnInfo: JSX.Element[] = [];

    if (!addOn) {
        return null;
    }

    if (addOn.displayType === DisplayType.SUBJECT) {
        addOn.subjects?.forEach((subject: AddOnSubject) =>
            addOnInfo.push(
                <React.Fragment key={addOn.resourceId + "-" + subject.subjectId}>
                    <span className="subject-per-addon">
                        {subject.quantity} {subject.name}
                    </span>
                    <br />
                </React.Fragment>
            )
        );
    }

    if (addOn.displayType === DisplayType.DATE_SELECT) {
        addOn.dates?.forEach((date: Date) =>
            addOnInfo.push(
                <React.Fragment key={getAddOnDateKey(addOn, date)}>
                    <span className="day-per-addon">{moment(date).format(DATE_FORMAT.DAY_WITH_DATE)}</span>
                    <br />
                </React.Fragment>
            )
        );
    }

    /* jscpd:ignore-start */
    /**
     * Display the subjects and dates of a dateSubjectSelection in the same manner as if they were separate subjects and dates.
     * In the future, we can show the selected subjects per date.
     */
    if (addOn.displayType === DisplayType.DATE_SELECT_SUBJECT) {
        addOn.dateSubjectSelections?.forEach((dateSubjectSelection: DateSubjectSelection, index: number) => {
            if (index === 0) {
                dateSubjectSelection.subjects.forEach((subject: AddOnSubject) =>
                    addOnInfo.push(
                        <React.Fragment key={addOn.resourceId + "-" + subject.subjectId}>
                            <span className="subject-per-addon">
                                {subject.quantity} {subject.name}
                            </span>
                            <br />
                        </React.Fragment>
                    )
                );
            }
            addOnInfo.push(
                <React.Fragment key={getAddOnDateKey(addOn, dateSubjectSelection.date)}>
                    <span className="day-per-addon">{moment(dateSubjectSelection.date).format(DATE_FORMAT.DAY_WITH_DATE)}</span>
                    <br />
                </React.Fragment>
            );
        });
    }
    /* jscpd:ignore-end */

    if (addOn.displayType === DisplayType.DATE_RANGE) {
        const addOnStartDate = addOn?.dates?.[0];
        const addOnEndDate = addOn?.dates?.[addOn.dates.length - 1];

        if (moment(addOnStartDate).format(DATE_FORMAT.DEFAULT) !== reservationStartDate || moment(addOnEndDate).format(DATE_FORMAT.DEFAULT) !== reservationEndDate) {
            if (addOnStartDate === addOnEndDate) {
                addOnInfo.push(
                    <React.Fragment>
                        <span className="date-per-addon">{`${moment(addOnStartDate).format(DATE_FORMAT.DAY_WITH_DATE)}`}</span>
                        <br />
                    </React.Fragment>
                );
            } else {
                addOnInfo.push(
                    <React.Fragment>
                        <span className="date-per-addon">
                            {`${moment(addOnStartDate).format(DATE_FORMAT.DAY_WITH_DATE)} ${getI18nLocaleString(namespaceList.customerBillWidget, "till", currentLocale, site)} ${moment(
                                addOnEndDate
                            ).format(DATE_FORMAT.DAY_WITH_DATE)}`}
                        </span>
                        <br />
                    </React.Fragment>
                );
            }
        }
    }

    return <div className="bill-dates">{addOnInfo}</div>;
};

const getAddOnDateKey = (addOn: SelectedAddOn, date: Date): string => {
    const dateObj = new Date(date);
    return `${addOn.resourceId}-${dateObj.getFullYear()}-${dateObj.getMonth()}-${dateObj.getDate()}`;
};
