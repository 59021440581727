import * as React from "react";

import { FontConfigurationOptions, FontConfigurationSpec, defaultFontConfigurationValues } from "../../../utils/menufont.util";
import { FormSpec, localized, multiSelectSpec, multiSelectStylePicker } from "../../../form-specs";
import { LinkingSpecOptions, linkingSpec } from "../../../inputSpecs/linkingSpec";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { MultiSelectOptionStringValue, WithId } from "@maxxton/cms-api";
import { PageWidgetSpec, WidgetSpec, WidgetType } from "../../widget";

import { BillLineType } from "@maxxton/cms-mxts-api";
import { BillType } from "../../../redux/reducers/billReducer";
import { DynamicCustomerBill } from "./Bill";
import { LocalizeDcAndRcOptions } from "../owner/book/choiceSelection";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { SelectOption } from "../../../form-specs/formSpec.types";
import { WidgetGroup } from "../../widget.enum";
import { dynamicFieldPrice } from "../../../components/utils";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import { linkingSpecDefaultValues } from "../../../utils/linking.util";
import localizedDcAndRcOptions from "../../../inputSpecs/localizedDcAndRcOptions";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions extends LocalizedTitleOptions, WithId {
    styleIds?: string[];
    typeOfBillLines: MultiSelectOptionStringValue[];
    showCurrency: boolean;
    showDates: boolean;
    showDescriptionForChoice?: boolean;
    removeChoice?: boolean;
    removeUnitPreference?: boolean;
    quantity?: boolean;
    showExcludingVatPrice?: boolean;
    showTitleAddons?: boolean;
    mergeAccoBillLines?: boolean;
    mergeResortBillLines?: boolean;
    mergeTaxBillLines?: boolean;
    mergeVatBillLines?: boolean;
    overwriteMergedAccoBillLineName?: boolean;
    priceInfoText: string;
    showReferencePrice: boolean;
    localizedContent?: localizedContent[];
    isBillLinesGrouped?: boolean;
    addDecimalToBillPrices?: boolean;
    showSpecialSeparatelyAfterMerging?: boolean;
    showOneSpecialPerLineAfterMerging?: boolean;
    useCashflowruleNameForSpecial?: boolean;
    useConfiguredCashflowRuleName?: boolean;
    useCashflowruleNameForAccoType?: boolean;
    useAccommodationCodeInBillLine?: boolean;
    useAsShoppingCart?: boolean;
    useDynamicSearchButton?: boolean;
    targetBill?: BillType;
    linking: LinkingSpecOptions;
    useAsDynamicBookingUrlLink: boolean;
    useForActivityPlanner?: boolean;
    removeBorder?: boolean;
    fontConfiguration: FontConfigurationOptions;
    showLabelAboveBill?: boolean;
    useAsAgentBill?: boolean;
    useDynamicFieldForActivity?: boolean;
    activityDynamicFieldCodes?: NumberMultiSelectOption[];
    enableSeparateBillLine?: boolean;
    enablePriceBreakDown?: boolean;
    useForWorkOrderReservation?: boolean;
    workOrderLocalizedOptions?: LocalizeDcAndRcOptions[];
    showIncludedAddOnsWithExtras?: boolean;
    showUnitPreferenceBillLineWithExtras?: boolean;
    showTitleForSpecials?: boolean;
}

export interface localizedContent {
    locale: string;
    accoBillLineName: string;
    accoBillLineInclSpecialName: string;
    zeroBillPriceText: string;
    localizedTitleAddons: string;
    resortArticleLabel: string;
    localizedTitleSpecials?: string;
}

export enum FrontendBillLineType {
    COST_TYPE = "COST_TYPE",
    RESORT_ARTICLE_LABEL = "RESORT_ARTICLE_LABEL",
}

const TARGETS = ["customer-bill"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "customer-bill-widget-options",
    name: getI18nLocaleObject(namespaceList.customerBillWidget, "billWidget"),
    pluralName: getI18nLocaleObject(namespaceList.customerBillWidget, "customerBillWidget"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            multiSelectSpec("typeOfBillLines", getI18nLocaleObject(namespaceList.customerBillWidget, "billLines"), true, () => [
                                {
                                    value: BillLineType.RESERVED_RESOURCE,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "reservedResource"),
                                },
                                {
                                    value: BillLineType.ACTIVITY,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "activity"),
                                },
                                {
                                    value: BillLineType.ADDON,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "addon"),
                                },
                                {
                                    value: FrontendBillLineType.COST_TYPE,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "costType"),
                                },
                                {
                                    value: BillLineType.TOTAL,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "total"),
                                },
                                {
                                    value: BillLineType.SUBTOTAL,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "subtotal"),
                                },
                                {
                                    value: BillLineType.TAX,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "tax"),
                                },
                                {
                                    value: BillLineType.TOTAL_EXCL_TAX,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "totalExcludingVat"),
                                },
                                {
                                    value: BillLineType.WARRANT,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "warrant"),
                                },
                                {
                                    value: BillLineType.RESORT_ARTICLE,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "resortArticle"),
                                },
                                {
                                    value: BillLineType.RESORT_ARTICLES_TOTAL,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "resortArticlesTotal"),
                                },
                                {
                                    value: BillLineType.STAY_ARTICLE,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "stayArticle"),
                                },
                                {
                                    value: FrontendBillLineType.RESORT_ARTICLE_LABEL,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "resortArticleLabel"),
                                },
                                {
                                    value: BillLineType.INTERNAL_PRICE,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "internalPrice"),
                                },
                                {
                                    value: BillLineType.DUE_AMOUNT,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "dueAmount"),
                                },
                                // Temporarily keeping all bill lins later we will remove the useless bill lines
                                {
                                    value: BillLineType.VAT,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "vatTax"),
                                },
                                {
                                    value: BillLineType.VAT_EXCL_LINE,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "vatExclusiveLine"),
                                },
                                {
                                    value: BillLineType.TOTAL_EXCL_VAT,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "vatExclusiveVat"),
                                },
                                {
                                    value: BillLineType.PAID_AMOUNT,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "paidAmount"),
                                },
                                {
                                    value: BillLineType.ADDON_TOTAL_PRICE,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "showAddonTotalPrice"),
                                },
                                {
                                    value: BillLineType.MY_ENV_RESERVATION_ADDON,
                                    text: getI18nLocaleObject(namespaceList.customerBillWidget, "myEnvReservationAddon"),
                                },
                            ]),
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        variable: "resortArticleLabel",
                                        label: getI18nLocaleObject(namespaceList.customerBillWidget, "resortArticleLabel"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => options?.typeOfBillLines.some((type) => type.value === "RESORT_ARTICLE_LABEL"),
                            }),
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "mergeAccoBillLines"),
                                variable: "mergeAccoBillLines",
                                type: "checkbox",
                                default: false,
                                groupName: "mergeAccoBillLinesGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.customerBillWidget, "mergeAccoBillLinesGroupTitle"),
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "mergeResortBillLines"),
                                variable: "mergeResortBillLines",
                                type: "checkbox",
                                default: false,
                                groupName: "mergeAccoBillLinesGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.customerBillWidget, "mergeAccoBillLinesGroupTitle"),
                                visible: (options: WidgetOptions) =>
                                    !!options.typeOfBillLines.length && options.typeOfBillLines.some((contentType) => contentType.value === BillLineType.RESORT_ARTICLE),
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "mergeTaxBillLines"),
                                variable: "mergeTaxBillLines",
                                type: "checkbox",
                                default: false,
                                groupName: "mergeAccoBillLinesGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.customerBillWidget, "mergeAccoBillLinesGroupTitle"),
                                visible: (options: WidgetOptions) => !!options.typeOfBillLines.length && options.typeOfBillLines.some((contentType) => contentType.value === BillLineType.TAX),
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "mergeVatBillLines"),
                                variable: "mergeVatBillLines",
                                type: "checkbox",
                                default: false,
                                groupName: "mergeAccoBillLinesGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.customerBillWidget, "mergeAccoBillLinesGroupTitle"),
                                visible: (options: WidgetOptions) => !!options.typeOfBillLines.length && options.typeOfBillLines.some((contentType) => contentType.value === BillLineType.VAT),
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "showSpecialSeparatelyAfterMerging"),
                                variable: "showSpecialSeparatelyAfterMerging",
                                type: "checkbox",
                                default: false,
                                groupName: "mergeAccoBillLinesGroup",
                                visible: (options: WidgetOptions) => options?.mergeAccoBillLines || false,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "showOneSpecialPerLineAfterMerging"),
                                variable: "showOneSpecialPerLineAfterMerging",
                                type: "checkbox",
                                default: false,
                                groupName: "mergeAccoBillLinesGroup",
                                visible: (options: WidgetOptions) => options?.mergeAccoBillLines || false,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "showTitleForSpecials"),
                                variable: "showTitleForSpecials",
                                type: "checkbox",
                                default: false,
                                groupName: "mergeAccoBillLinesGroup",
                                visible: (options: WidgetOptions) => (options.mergeAccoBillLines ? !!options.showSpecialSeparatelyAfterMerging : true),
                            },
                            localized({
                                variable: "localizedContent",
                                groupName: "mergeAccoBillLinesGroup",
                                tabContent: [
                                    {
                                        variable: "localizedTitleSpecials",
                                        label: getI18nLocaleObject(namespaceList.customerBillWidget, "localizedTitleSpecials"),
                                        type: "text",
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForEmptyText"),
                                    },
                                ],
                                visible: (options: WidgetOptions) => !!options.showTitleForSpecials,
                            }),
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "overwriteMergedAccoBillLineName"),
                                variable: "overwriteMergedAccoBillLineName",
                                type: "checkbox",
                                default: false,
                                groupName: "mergeAccoBillLinesGroup",
                                visible: (options: WidgetOptions) => options?.mergeAccoBillLines || false,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "enableSeparateBillLine"),
                                variable: "enableSeparateBillLine",
                                type: "checkbox",
                                default: false,
                                groupName: "mergeAccoBillLinesGroup",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "enablePriceBreakDown"),
                                variable: "enablePriceBreakDown",
                                type: "checkbox",
                                default: false,
                                groupName: "mergeAccoBillLinesGroup",
                            },
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        variable: "accoBillLineName",
                                        label: getI18nLocaleObject(namespaceList.customerBillWidget, "accoBillLineName"),
                                        type: "text",
                                    },
                                    {
                                        variable: "accoBillLineInclSpecialName",
                                        label: getI18nLocaleObject(namespaceList.customerBillWidget, "accoBillLineInclSpecialName"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => (options?.mergeAccoBillLines && options?.overwriteMergedAccoBillLineName) || false,
                            }),
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "useConfiguredCashflowRuleName"),
                                variable: "useConfiguredCashflowRuleName",
                                type: "checkbox",
                                default: false,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "useCashflowruleNameForSpecial"),
                                variable: "useCashflowruleNameForSpecial",
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => !!options.useConfiguredCashflowRuleName,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "useCashflowruleNameForAccoType"),
                                variable: "useCashflowruleNameForAccoType",
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => !!options.useConfiguredCashflowRuleName,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "useAccommodationCodeInBillLine"),
                                variable: "useAccommodationCodeInBillLine",
                                type: "checkbox",
                                default: false,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "moveIncludedAddOnsToExtras"),
                                variable: "showIncludedAddOnsWithExtras",
                                type: "checkbox",
                                default: false,
                                groupName: "addOnBillLinesGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.customerBillWidget, "addOnBillLinesGroup"),
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "moveUnitPreferenceBillLineToExtras"),
                                variable: "showUnitPreferenceBillLineWithExtras",
                                type: "checkbox",
                                default: false,
                                groupName: "addOnBillLinesGroup",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "useAsShoppingCart"),
                                variable: "useAsShoppingCart",
                                type: "checkbox",
                                default: false,
                                groupName: "useAsShoppingCartGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.customerBillWidget, "useAsShoppingCartGroupTitle"),
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "useForWorkOrderReservation"),
                                variable: "useForWorkOrderReservation",
                                type: "checkbox",
                                default: false,
                                groupName: "useAsShoppingCartGroup",
                                visible: (options: WidgetOptions) => !!options.useAsShoppingCart,
                            },
                            localized({
                                variable: "workOrderLocalizedOptions",
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "workOrderLocalizedOptions"),
                                groupName: "useAsShoppingCartGroup",
                                tabContent: [...localizedDcAndRcOptions()],
                                visible: (options: WidgetOptions) => !!options.useForWorkOrderReservation,
                            }),
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "useDynamicSearchButton"),
                                variable: "useDynamicSearchButton",
                                type: "checkbox",
                                default: false,
                                groupName: "useAsShoppingCartGroup",
                                visible: (options: WidgetOptions) => !!options.useAsShoppingCart,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "useAsAgentBill"),
                                variable: "useAsAgentBill",
                                type: "checkbox",
                                default: false,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "useWithActivityPlanner"),
                                variable: "useForActivityPlanner",
                                type: "checkbox",
                                groupName: "activityPlannerGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "activityPlannerGroupTitle"),
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "useDynamicFieldForActivity"),
                                variable: "useDynamicFieldForActivity",
                                type: "checkbox",
                                default: false,
                                groupName: "activityPlannerGroup",
                                visible: (options: WidgetOptions) => !!options.useForActivityPlanner,
                            },
                            {
                                ...dynamicFieldPrice("activityDynamicFieldCodes", undefined, undefined, "Activity"),
                                groupName: "activityPlannerGroup",
                                visible: (options: WidgetOptions) => !!options.useDynamicFieldForActivity && !!options.useForActivityPlanner,
                            },
                            ...getWidgetTitleOptions<WidgetOptions>(),
                            {
                                variable: "targetBill",
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "targetBill"),
                                type: "autocomplete",
                                placeholder: getI18nLocaleObject(namespaceList.customerBillWidget, "selectTargetBill"),
                                options: async (): Promise<Array<SelectOption<BillType>>> => [
                                    {
                                        value: "mainBill",
                                        label: getI18nLocaleObject(namespaceList.customerBillWidget, "mainBill"),
                                    },
                                    {
                                        value: "myEnvAdditionsBill",
                                        label: getI18nLocaleObject(namespaceList.customerBillWidget, "myEnvAdditionsBill"),
                                    },
                                ],
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "tabOptions"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "showCurrency"),
                                variable: "showCurrency",
                                type: "checkbox",
                                default: true,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "showDescription"),
                                variable: "showDescriptionForChoice",
                                type: "checkbox",
                                default: false,
                                groupName: "showDescriptionForChoiceGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.customerBillWidget, "showDescriptionForChoiceGroupTitle"),
                            },
                            {
                                variable: "priceInfoText",
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "priceInfoText"),
                                type: "select",
                                groupName: "showDescriptionForChoiceGroup",
                                optionList: [
                                    {
                                        value: "hover",
                                        label: getI18nLocaleObject(namespaceList.customerBillWidget, "hoverInfo"),
                                    },
                                    {
                                        value: "modal",
                                        label: getI18nLocaleObject(namespaceList.customerBillWidget, "modalInfo"),
                                    },
                                ],
                                visible: (options: WidgetOptions) => !!options.showDescriptionForChoice,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "removeChoice"),
                                variable: "removeChoice",
                                type: "checkbox",
                                default: false,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "removeUnitPreference"),
                                variable: "removeUnitPreference",
                                type: "checkbox",
                                default: false,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "quantity"),
                                variable: "quantity",
                                type: "checkbox",
                                default: false,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "showReferencePrice"),
                                variable: "showReferencePrice",
                                type: "checkbox",
                                default: false,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "showExcludingVatPrice"),
                                variable: "showExcludingVatPrice",
                                type: "checkbox",
                                default: false,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "showTitleAddons"),
                                variable: "showTitleAddons",
                                type: "checkbox",
                                default: false,
                                groupName: "showTitleAddonsGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.customerBillWidget, "showTitleAddonsGroupTitle"),
                            },
                            localized({
                                variable: "localizedContent",
                                groupName: "showTitleAddonsGroup",
                                tabContent: [
                                    {
                                        variable: "localizedTitleAddons",
                                        label: getI18nLocaleObject(namespaceList.customerBillWidget, "localizedTitleAddons"),
                                        type: "text",
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForEmptyText"),
                                    },
                                ],
                                visible: (options: WidgetOptions) => !!options.showTitleAddons,
                            }),
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "showDates"),
                                variable: "showDates",
                                type: "checkbox",
                                default: false,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.customerBillWidget, "groupedBillLines"),
                                variable: "isBillLinesGrouped",
                                type: "checkbox",
                                default: false,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "addDecimalToPrices"),
                                variable: "addDecimalToBillPrices",
                                type: "checkbox",
                                default: false,
                            },
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        variable: "zeroBillPriceText",
                                        label: getI18nLocaleObject(namespaceList.widgetAdditions, "zeroBillPriceText"),
                                        type: "text",
                                        placeholder: getI18nLocaleObject(namespaceList.widgetAdditions, "placeholderZeroBillPriceText"),
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            ...titleStylingOptions<WidgetOptions>(),
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "removeBorder"),
                                variable: "removeBorder",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "showLabelAboveBill"),
                                variable: "showLabelAboveBill",
                                type: "checkbox",
                            },
                            FontConfigurationSpec<WidgetOptions, keyof WidgetOptions>({ variable: "fontConfiguration" }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "linking"),
                    properties: [
                        [
                            {
                                variable: "useAsDynamicBookingUrlLink",
                                label: getI18nLocaleObject(namespaceList.admin, "useAsDynamicBookUrlLink"),
                                type: "checkbox",
                            },
                            linkingSpec<WidgetOptions, keyof WidgetOptions>({ variable: "linking" }),
                        ],
                    ],
                    visible: (options: WidgetOptions) => options.targetBill === "myEnvAdditionsBill",
                },
            ],
        },
    ],
};

export function customerBillWidget(type: WidgetType) {
    const customerBill: WidgetSpec<WidgetOptions> = {
        id: "customerBillWidget",
        type,
        widgetGroup: type === "page" || type === "resultsPanel" ? WidgetGroup.BOOKINGS_MODULE : WidgetGroup.OTHER,
        name: getI18nLocaleObject(namespaceList.customerBillWidget, "customerBillWidget"),
        description: getI18nLocaleObject(namespaceList.customerBillWidget, "customerBillWidgetDescription"),
        optionsForm: widgetOptionsForm,
        defaultOptions: (): Omit<WidgetOptions, "_id"> => ({
            linking: linkingSpecDefaultValues,
            useAsDynamicBookingUrlLink: false,
            typeOfBillLines: [],
            showCurrency: true,
            showDates: false,
            priceInfoText: "",
            showReferencePrice: false,
            isBillLinesGrouped: false,
            useCashflowruleNameForSpecial: false,
            fontConfiguration: defaultFontConfigurationValues,
        }),
    };
    (customerBill as PageWidgetSpec<WidgetOptions>).render = async (widget, context) => {
        const { styleIds } = widget.options;
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds || []);

        return <DynamicCustomerBill options={widget.options} context={context} className={className} widgetId={widget._id} />;
    };
    return customerBill;
}
