import * as React from "react";

import { ActivityStockResult, ResourceActivitiesDetailsWithDynamicField, SubjectQuantityWithDetailsId } from "../../../../page/activityPlanner/activityPlanner.types";
import { setLocalActivityTime, setSubjectNameForActivityBillLine } from "../../../../page/activityPlanner/activityPlanner.util";

import { BillLine } from "@maxxton/cms-mxts-api";
import { State } from "../../../../../redux";
import { TicketingTypes } from "../../../../page/activityPlanner/activityPlanner.enum";
import { getI18nLocaleString } from "../../../../../i18n";
import { getMxtsEnv } from "../../../../mxts";
import { groupBy } from "lodash";
import namespaceList from "../../../../../i18n/namespaceList";
import { useBillContext } from "../../BillContext";
import { useSelector } from "react-redux";

interface ExtraActivityInfoProps {
    billLine: BillLine;
}
export const ExtraActivityInfo: React.FC<ExtraActivityInfoProps> = (props: ExtraActivityInfoProps): JSX.Element | null => {
    const { selectedActivities, activityTicketQuantity, subject } = useSelector((state: State) => state.dynamicFilter);
    const { reservedResourceSubjectQuantities } = useSelector((state: State) => state.activityPlannerState);
    const billStateReservedResources = useSelector((state: State) => state.billState.mainBill?.reservedResources);

    const { cmsContext } = useBillContext();
    const { billLine } = props;

    const foundResourceActivity = selectedActivities?.find((resourceActivity) => resourceActivity.resourceActivityDetailsId === billLine?.reservedResource?.resourceActivityDetailsId);
    const [resourceActivity, setResourceActivity] = React.useState<ResourceActivitiesDetailsWithDynamicField | undefined>(foundResourceActivity);
    const [activityInfo, setActivityInfo] = React.useState<JSX.Element[]>([]);

    React.useEffect(() => {
        let subjectQuantitiesForActivity = reservedResourceSubjectQuantities?.filter(
            (subjectQuantity) => Number(subjectQuantity.resourceActivityDetailsId) === Number(resourceActivity?.resourceActivityDetailsId)
        );
        const formatTimezonesForActivity = async () => {
            let updatedResourceActivity = resourceActivity;
            if (resourceActivity && (resourceActivity?.startTime.includes("Z") || resourceActivity?.endTime.includes("Z"))) {
                updatedResourceActivity = await setLocalActivityTime(resourceActivity, cmsContext);
            }
            const newActivityInfo: JSX.Element[] = [];
            newActivityInfo.push(
                <div className="bill-extra-info__time">
                    <span>{` (${updatedResourceActivity?.startTime} - ${updatedResourceActivity?.endTime}) `}</span>
                </div>
            );

            // Add subject name in subjectQuantitiesForActivity
            const env = await getMxtsEnv(cmsContext);
            subjectQuantitiesForActivity = await setSubjectNameForActivityBillLine(env, subjectQuantitiesForActivity, updatedResourceActivity);

            if (updatedResourceActivity?.resourceActivity.resortActivity.ticketingType === TicketingTypes.TICKET_PER_GROUP && updatedResourceActivity?.activityReservabilities?.length) {
                // Group subjectQuantitiesForActivity data based on ticketNumber so that subjects inside specific tickets can be shown as a extraInfo
                const groupSubjectByTicketNumber = groupBy(subjectQuantitiesForActivity, "ticketNumber");

                for (const key in groupSubjectByTicketNumber) {
                    if (groupSubjectByTicketNumber.hasOwnProperty(key)) {
                        newActivityInfo.push(
                            <div key={key} className="bill-extra-info__subjects">
                                {Object.keys(groupSubjectByTicketNumber).length > 1 && <div>{`${getI18nLocaleString(namespaceList.widgetActivityPlanner, "singleTicket")} ${key}`}</div>}
                                {groupSubjectByTicketNumber[key].map((subjectinfo) => renderSubjectInfo(subjectinfo))}
                            </div>
                        );
                    }
                }
            }

            if (
                updatedResourceActivity?.resourceActivity.resortActivity.ticketingType === TicketingTypes.TICKET_PER_SUBJECT &&
                updatedResourceActivity?.activityReservabilities?.length &&
                subjectQuantitiesForActivity?.length
            ) {
                if (billLine.qrvType === "SUBJECT") {
                    // When "Rate per subject" is configured for Ticket per subject activity
                    const subjectIds = billStateReservedResources
                        ?.find((billStateReservedResource) => billStateReservedResource.resourceId === billLine.resourceId)
                        ?.usedCashflowrules?.find((usedCashflowrule) => usedCashflowrule.cashflowruleId === billLine.cashflowruleId)?.qrvSubjects;
                    newActivityInfo.push(
                        <div className="bill-extra-info__subjects">
                            {subjectIds?.map((subjectId) => {
                                const subjectinfo = subjectQuantitiesForActivity?.find((subjectQuantityForActivity) => subjectQuantityForActivity.subjectId === subjectId);
                                if (subjectinfo) {
                                    return renderSubjectInfo(subjectinfo);
                                }
                            })}
                        </div>
                    );
                } else {
                    // When "Fixed rate" is configured for Ticket per subject activity
                    newActivityInfo.push(<div className="bill-extra-info__subjects">{subjectQuantitiesForActivity?.map((subjectinfo) => renderSubjectInfo(subjectinfo))}</div>);
                }
            }
            setActivityInfo(newActivityInfo);
            setResourceActivity(updatedResourceActivity);
        };
        formatTimezonesForActivity();
    }, [resourceActivity, reservedResourceSubjectQuantities, subject, activityTicketQuantity, billLine.qrvType, billLine.resourceId, billLine.cashflowruleId, billStateReservedResources]);

    const renderSubjectInfo = (subjectinfo: SubjectQuantityWithDetailsId) => {
        const activityStock = resourceActivity?.activityReservabilities.find((activityReservability) => activityReservability.activityStock.subjectId === subjectinfo.subjectId)?.activityStock;
        return (
            <div className="activity-subject-info" key={subjectinfo.subjectId}>
                <span className="activity-subject-info__quantity">
                    {subjectinfo.quantity} {(activityStock as ActivityStockResult)?.subjectName}
                </span>
            </div>
        );
    };
    return <div className="bill-extra-info">{activityInfo}</div>;
};
